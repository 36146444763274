/* eslint-disable @typescript-eslint/no-unused-vars */
import { RankedTester, rankWith, RendererProps, uiTypeIs, VerticalLayout } from "@jsonforms/core";
import { JsonFormsDispatch, withJsonFormsLayoutProps } from "@jsonforms/react";
import React, { FunctionComponent } from "react";

export const verticalLayoutTester: RankedTester = rankWith(1, uiTypeIs("VerticalLayout"));

const VerticalLayoutRenderer = (props: RendererProps) => {
    const { data: _data, ...otherProps } = props;
    // We don't hand over data to the layout renderer to avoid rerendering it with every data change
    return <VerticalLayoutRendererComponent {...otherProps} />;
};

const VerticalLayoutRendererComponent: FunctionComponent<RendererProps> = React.memo(
    function VerticalLayoutRendererComponent({ schema, uischema, path, enabled, renderers, cells }: RendererProps) {
        const verticalLayout = uischema as VerticalLayout;

        return verticalLayout.elements.map((child, index) => {
            return (
                <div key={`${path}-${index}`}>
                    <JsonFormsDispatch
                        renderers={renderers}
                        cells={cells}
                        uischema={child}
                        schema={schema}
                        path={path}
                        enabled={enabled}
                    />
                </div>
            );
        });
    }
);

export default withJsonFormsLayoutProps(VerticalLayoutRenderer, false);
