import { clsx, type ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
    return twMerge(clsx(inputs));
}

export function projectPath(type: "d" | "c") {
    return type === "d" ? "/:account/d/:project" : "/:account/c/:project";
}
