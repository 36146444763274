/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: { input: string; output: string };
    String: { input: string; output: string };
    Boolean: { input: boolean; output: boolean };
    Int: { input: number; output: number };
    Float: { input: number; output: number };
    /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
    JSONObject: { input: any; output: any };
};

export type Account = Node & {
    __typename?: "Account";
    _meta?: Maybe<SystemMetaData>;
    _partition: Account;
    deleting?: Maybe<Scalars["Boolean"]["output"]>;
    /** More info about the account for internal users */
    description?: Maybe<Scalars["String"]["output"]>;
    environments?: Maybe<Array<Environment>>;
    features?: Maybe<AccountFeatures>;
    /**
     * To be replaced with
     * owner: User!
     */
    firstAdmin?: Maybe<User>;
    /** Accounts are partitioned by generated primary key to snsure uniform distribution of clients */
    id: Scalars["ID"]["output"];
    invites?: Maybe<Array<AccountInvite>>;
    limits?: Maybe<AccountLimits>;
    lookup?: Maybe<AccountLookup2>;
    lookups?: Maybe<Array<AccountLookup>>;
    /** Accounts can have human readable name for some reason */
    name: Scalars["String"]["output"];
    overlays?: Maybe<Array<Overlay>>;
    packageSettings?: Maybe<PackageSettings>;
    packages?: Maybe<Array<Package>>;
    projects?: Maybe<Array<Project>>;
    projects2?: Maybe<Array<Project2>>;
    resourceGroup?: Maybe<ResourceGroup>;
    /**
     * Unique identifier for the account
     *
     * In case of partitioned entity this will be a as separate system object. We can use fragment to
     * simplify the lookups as with normal entities.
     *
     * Created entity will be something like this
     *
     * @entity (collection: "account")  {
     *   slug: ID! @id(idType: EXTERNAL)
     *   account: Account! @materialize(fragment: $fragment) @cascade(delete)
     * }
     *
     * The difficulty is that query engine needs to respect this additional entity for it to be useful
     * Also it will make updating a bit more tricky as we need to ensure consistency across potentially
     * different partitions
     *
     * actial field in future:
     * slug: String! @unique(fragment: " {name}")
     * @deprecated Use lookup instead
     */
    slug?: Maybe<Scalars["String"]["output"]>;
    storages?: Maybe<Array<Storage>>;
    teams?: Maybe<Array<Team>>;
    users?: Maybe<Array<AccountUser>>;
    /**
     * Account primary website
     * For now not validated, but we might consider validation in the future
     */
    website?: Maybe<Scalars["String"]["output"]>;
};

export type AccountEnvironmentsArgs = {
    after?: InputMaybe<Scalars["String"]["input"]>;
    filter?: InputMaybe<Environment_Filter>;
    first?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<Environment_Sort>;
};

export type AccountInvitesArgs = {
    after?: InputMaybe<Scalars["String"]["input"]>;
    filter?: InputMaybe<AccountInvite_Filter>;
    first?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<AccountInvite_Sort>;
};

export type AccountLookupsArgs = {
    after?: InputMaybe<Scalars["String"]["input"]>;
    filter?: InputMaybe<AccountLookup_Filter>;
    first?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<AccountLookup_Sort>;
};

export type AccountOverlaysArgs = {
    after?: InputMaybe<Scalars["String"]["input"]>;
    filter?: InputMaybe<Overlay_Filter>;
    first?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<Overlay_Sort>;
};

export type AccountPackagesArgs = {
    after?: InputMaybe<Scalars["String"]["input"]>;
    filter?: InputMaybe<Package_Filter>;
    first?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<Package_Sort>;
};

export type AccountProjectsArgs = {
    after?: InputMaybe<Scalars["String"]["input"]>;
    filter?: InputMaybe<Project_Filter>;
    first?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<Project_Sort>;
};

export type AccountProjects2Args = {
    after?: InputMaybe<Scalars["String"]["input"]>;
    filter?: InputMaybe<Project2_Filter>;
    first?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<Project2_Sort>;
};

export type AccountStoragesArgs = {
    after?: InputMaybe<Scalars["String"]["input"]>;
    filter?: InputMaybe<Storage_Filter>;
    first?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<Storage_Sort>;
};

export type AccountTeamsArgs = {
    after?: InputMaybe<Scalars["String"]["input"]>;
    filter?: InputMaybe<Team_Filter>;
    first?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<Team_Sort>;
};

export type AccountUsersArgs = {
    after?: InputMaybe<Scalars["String"]["input"]>;
    filter?: InputMaybe<AccountUser_Filter>;
    first?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<AccountUser_Sort>;
};

export type AccountFeatures = Node & {
    __typename?: "AccountFeatures";
    _meta?: Maybe<SystemMetaData>;
    _partition: Account;
    account: Account;
    aspects?: Maybe<Scalars["Boolean"]["output"]>;
    externalPermissions?: Maybe<Scalars["Boolean"]["output"]>;
    id: Scalars["ID"]["output"];
    teams?: Maybe<Scalars["Boolean"]["output"]>;
};

export type AccountFeatures_Filter = {
    _meta?: InputMaybe<SystemMetaData_Filter>;
    _search?: InputMaybe<Scalars["String"]["input"]>;
    account?: InputMaybe<NodeReference_Filter>;
    aspects?: InputMaybe<Boolean_Filter>;
    externalPermissions?: InputMaybe<Boolean_Filter>;
    id?: InputMaybe<Id_Filter>;
    teams?: InputMaybe<Boolean_Filter>;
};

export type AccountFeatures_Sort = {
    by?: InputMaybe<Array<InputMaybe<AccountFeatures_Sort_Options>>>;
    order?: InputMaybe<Sort>;
};

export type AccountFeatures_Sort_Options = "_createdAt" | "_updatedAt" | "id";

export type AccountInvite = Node & {
    __typename?: "AccountInvite";
    _meta?: Maybe<SystemMetaData>;
    _partition: Account;
    account: Account;
    codeEmail: Scalars["String"]["output"];
    email: Scalars["ID"]["output"];
    forwardProtection?: Maybe<Scalars["Boolean"]["output"]>;
    id: Scalars["ID"]["output"];
    inviter?: Maybe<User>;
    resent?: Maybe<Scalars["String"]["output"]>;
    userByEmail?: Maybe<User>;
};

export type AccountInvite_Filter = {
    _meta?: InputMaybe<SystemMetaData_Filter>;
    _search?: InputMaybe<Scalars["String"]["input"]>;
    account?: InputMaybe<NodeReference_Filter>;
    codeEmail?: InputMaybe<String_Filter>;
    email?: InputMaybe<Id_Filter>;
    forwardProtection?: InputMaybe<Boolean_Filter>;
    id?: InputMaybe<Id_Filter>;
    inviter?: InputMaybe<NodeReference_Filter>;
    resent?: InputMaybe<DateTime_Filter>;
    userByEmail?: InputMaybe<NodeReference_Filter>;
};

export type AccountInvite_Sort = {
    by?: InputMaybe<Array<InputMaybe<AccountInvite_Sort_Options>>>;
    order?: InputMaybe<Sort>;
};

export type AccountInvite_Sort_Options = "_createdAt" | "_updatedAt" | "codeEmail" | "email" | "id";

export type AccountLimits = {
    __typename?: "AccountLimits";
    requestRate1h?: Maybe<Scalars["Int"]["output"]>;
    requestRate5m?: Maybe<Scalars["Int"]["output"]>;
};

export type AccountLimits_Filter = {
    requestRate1h?: InputMaybe<Int_Filter>;
    requestRate5m?: InputMaybe<Int_Filter>;
};

export type AccountLookup = Node & {
    __typename?: "AccountLookup";
    _meta?: Maybe<SystemMetaData>;
    _partition: AccountLookup;
    account: Account;
    id: Scalars["ID"]["output"];
    lookup: Scalars["ID"]["output"];
    type: Scalars["ID"]["output"];
};

export type AccountLookup2 = Node & {
    __typename?: "AccountLookup2";
    _meta?: Maybe<SystemMetaData>;
    _partition: AccountLookup2;
    account: Account;
    id: Scalars["ID"]["output"];
};

export type AccountLookup2_Filter = {
    _meta?: InputMaybe<SystemMetaData_Filter>;
    _search?: InputMaybe<Scalars["String"]["input"]>;
    account?: InputMaybe<NodeReference_Filter>;
    id?: InputMaybe<Id_Filter>;
};

export type AccountLookup2_Sort = {
    by?: InputMaybe<Array<InputMaybe<AccountLookup2_Sort_Options>>>;
    order?: InputMaybe<Sort>;
};

export type AccountLookup2_Sort_Options = "_createdAt" | "_updatedAt" | "id";

export type AccountLookup_Filter = {
    _meta?: InputMaybe<SystemMetaData_Filter>;
    _search?: InputMaybe<Scalars["String"]["input"]>;
    account?: InputMaybe<NodeReference_Filter>;
    id?: InputMaybe<Id_Filter>;
    lookup?: InputMaybe<Id_Filter>;
    type?: InputMaybe<Id_Filter>;
};

export type AccountLookup_Sort = {
    by?: InputMaybe<Array<InputMaybe<AccountLookup_Sort_Options>>>;
    order?: InputMaybe<Sort>;
};

export type AccountLookup_Sort_Options = "_createdAt" | "_updatedAt" | "id" | "lookup" | "type";

export type AccountStub = {
    __typename?: "AccountStub";
    id: Scalars["ID"]["output"];
    lookup: Scalars["String"]["output"];
    name: Scalars["String"]["output"];
    projects?: Maybe<Array<ProjectStub>>;
};

export type AccountTag = Node & {
    __typename?: "AccountTag";
    _meta?: Maybe<SystemMetaData>;
    _partition: Account;
    canRepeat?: Maybe<Scalars["Boolean"]["output"]>;
    description?: Maybe<Scalars["String"]["output"]>;
    fixedValues?: Maybe<Scalars["Boolean"]["output"]>;
    id: Scalars["ID"]["output"];
    needsValue?: Maybe<Scalars["Boolean"]["output"]>;
    securityDefiner: Scalars["Boolean"]["output"];
    sortOrder?: Maybe<Scalars["Int"]["output"]>;
    values?: Maybe<Array<Scalars["String"]["output"]>>;
};

export type AccountTagValue = {
    __typename?: "AccountTagValue";
    tag?: Maybe<AccountTag>;
    value?: Maybe<Scalars["String"]["output"]>;
};

export type AccountTagValue_Create = {
    tag?: InputMaybe<NodeReference>;
    value?: InputMaybe<Scalars["String"]["input"]>;
};

export type AccountTagValue_Filter = {
    tag?: InputMaybe<NodeReference_Filter>;
    value?: InputMaybe<String_Filter>;
};

export type AccountUser = Node & {
    __typename?: "AccountUser";
    _meta?: Maybe<SystemMetaData>;
    _partition: Account;
    account: Account;
    id: Scalars["ID"]["output"];
    tags?: Maybe<Array<AccountTagValue>>;
    teams?: Maybe<Array<TeamMember>>;
    user?: Maybe<User>;
};

export type AccountUserTeamsArgs = {
    after?: InputMaybe<Scalars["String"]["input"]>;
    filter?: InputMaybe<TeamMember_Filter>;
    first?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<TeamMember_Sort>;
};

export type AccountUser2 = Node & {
    __typename?: "AccountUser2";
    _meta?: Maybe<SystemMetaData>;
    _partition: Account;
    deleted?: Maybe<Scalars["Boolean"]["output"]>;
    id: Scalars["ID"]["output"];
    tags?: Maybe<Array<AccountTagValue>>;
    user: User;
};

export type AccountUser2_Create = {
    deleted?: InputMaybe<Scalars["Boolean"]["input"]>;
    tags?: InputMaybe<Array<AccountTagValue_Create>>;
    user: NodeReference;
};

export type AccountUser2_Filter = {
    _meta?: InputMaybe<SystemMetaData_Filter>;
    _search?: InputMaybe<Scalars["String"]["input"]>;
    deleted?: InputMaybe<Boolean_Filter>;
    id?: InputMaybe<Id_Filter>;
    user?: InputMaybe<NodeReference_Filter>;
};

export type AccountUser2_Lookup = {
    id?: InputMaybe<Scalars["ID"]["input"]>;
    keys?: InputMaybe<AccountUser2_Lookup_Keys>;
};

export type AccountUser2_Lookup_Keys = {
    user: User_Lookup;
};

export type AccountUser2_Sort = {
    by?: InputMaybe<Array<InputMaybe<AccountUser2_Sort_Options>>>;
    order?: InputMaybe<Sort>;
};

export type AccountUser2_Sort_Options = "_createdAt" | "_updatedAt" | "id" | "value";

export type AccountUser2_Update = {
    deleted?: InputMaybe<Scalars["Boolean"]["input"]>;
    tags?: InputMaybe<Array<AccountTagValue_Create>>;
};

export type AccountUser_Filter = {
    _meta?: InputMaybe<SystemMetaData_Filter>;
    _search?: InputMaybe<Scalars["String"]["input"]>;
    account?: InputMaybe<NodeReference_Filter>;
    id?: InputMaybe<Id_Filter>;
    user?: InputMaybe<NodeReference_Filter>;
};

export type AccountUser_Sort = {
    by?: InputMaybe<Array<InputMaybe<AccountUser_Sort_Options>>>;
    order?: InputMaybe<Sort>;
};

export type AccountUser_Sort_Options = "_createdAt" | "_updatedAt" | "id" | "value";

export type Account_Filter = {
    _meta?: InputMaybe<SystemMetaData_Filter>;
    _search?: InputMaybe<Scalars["String"]["input"]>;
    deleting?: InputMaybe<Boolean_Filter>;
    description?: InputMaybe<String_Filter>;
    firstAdmin?: InputMaybe<NodeReference_Filter>;
    id?: InputMaybe<Id_Filter>;
    limits?: InputMaybe<AccountLimits_Filter>;
    lookup?: InputMaybe<NodeReference_Filter>;
    name?: InputMaybe<String_Filter>;
    resourceGroup?: InputMaybe<NodeReference_Filter>;
    slug?: InputMaybe<String_Filter>;
    website?: InputMaybe<String_Filter>;
};

export type Account_Lookup = {
    id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type Account_Sort = {
    by?: InputMaybe<Array<InputMaybe<Account_Sort_Options>>>;
    order?: InputMaybe<Sort>;
};

export type Account_Sort_Options =
    | "_createdAt"
    | "_updatedAt"
    | "description"
    | "id"
    | "name"
    | "requestRate1h"
    | "requestRate5m"
    | "slug"
    | "website";

export type ActivateAuthSessionResponse = {
    __typename?: "ActivateAuthSessionResponse";
    pin?: Maybe<Scalars["String"]["output"]>;
    session: AuthSession;
};

/** Role based authorization. */
export type AuthOperations = "create" | "delete" | "fetch" | "list" | "modify";

export type AuthSession = {
    __typename?: "AuthSession";
    _emailSecretId?: Maybe<Scalars["String"]["output"]>;
    challenge: Scalars["String"]["output"];
    expiresAt: Scalars["String"]["output"];
    id: Scalars["ID"]["output"];
    state: AuthSessionState;
    type: AuthSessionType;
};

export type AuthSessionState = "completed" | "confirmed" | "pending" | "pin" | "qrcode";

export type AuthSessionType = "email" | "linking" | "passkey";

export type Authenticator = Node & {
    __typename?: "Authenticator";
    _meta?: Maybe<SystemMetaData>;
    darkMode?: Maybe<AuthenticatorTheme>;
    id: Scalars["ID"]["output"];
    lightMode?: Maybe<AuthenticatorTheme>;
    loginWithLabel?: Maybe<Scalars["String"]["output"]>;
    name: Scalars["String"]["output"];
    type: AuthenticatorType;
};

export type AuthenticatorTheme = {
    __typename?: "AuthenticatorTheme";
    backgroundColor?: Maybe<Scalars["String"]["output"]>;
    backgroundHoverColor?: Maybe<Scalars["String"]["output"]>;
    fontFamily?: Maybe<Scalars["String"]["output"]>;
    fontWeight?: Maybe<Scalars["String"]["output"]>;
    iconData?: Maybe<Scalars["String"]["output"]>;
    textColor?: Maybe<Scalars["String"]["output"]>;
};

export type AuthenticatorType = "generic" | "oauth" | "passkey";

export type Boolean_Filter = {
    _eq?: InputMaybe<Scalars["Boolean"]["input"]>;
    _null?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type Database = Node & {
    __typename?: "Database";
    _meta?: Maybe<SystemMetaData>;
    connectionStr?: Maybe<Scalars["String"]["output"]>;
    consumer?: Maybe<DatabaseConsumer>;
    database?: Maybe<Scalars["String"]["output"]>;
    id: Scalars["ID"]["output"];
    location?: Maybe<Location>;
    port?: Maybe<Scalars["Int"]["output"]>;
    privateIp?: Maybe<Scalars["String"]["output"]>;
    publicIp?: Maybe<Scalars["String"]["output"]>;
    redeploy?: Maybe<Scalars["Boolean"]["output"]>;
    replication?: Maybe<Array<DatabaseStats>>;
    resourceGroup?: Maybe<ResourceGroup>;
    version?: Maybe<Scalars["String"]["output"]>;
};

export type DatabaseConsumer = {
    __typename?: "DatabaseConsumer";
    globalEvents: Scalars["String"]["output"];
    subscription: Scalars["String"]["output"];
    userEvents: Scalars["String"]["output"];
};

export type DatabaseConsumer_Filter = {
    globalEvents?: InputMaybe<String_Filter>;
    subscription?: InputMaybe<String_Filter>;
    userEvents?: InputMaybe<String_Filter>;
};

export type DatabaseStats = {
    __typename?: "DatabaseStats";
    destination: Scalars["String"]["output"];
    distance: Scalars["Int"]["output"];
};

export type Database_Filter = {
    _meta?: InputMaybe<SystemMetaData_Filter>;
    _search?: InputMaybe<Scalars["String"]["input"]>;
    connectionStr?: InputMaybe<String_Filter>;
    consumer?: InputMaybe<DatabaseConsumer_Filter>;
    database?: InputMaybe<String_Filter>;
    id?: InputMaybe<Id_Filter>;
    location?: InputMaybe<NodeReference_Filter>;
    port?: InputMaybe<Int_Filter>;
    privateIp?: InputMaybe<String_Filter>;
    publicIp?: InputMaybe<String_Filter>;
    redeploy?: InputMaybe<Boolean_Filter>;
    resourceGroup?: InputMaybe<NodeReference_Filter>;
    version?: InputMaybe<String_Filter>;
};

export type Database_Sort = {
    by?: InputMaybe<Array<InputMaybe<Database_Sort_Options>>>;
    order?: InputMaybe<Sort>;
};

export type Database_Sort_Options =
    | "_createdAt"
    | "_updatedAt"
    | "connectionStr"
    | "database"
    | "globalEvents"
    | "id"
    | "port"
    | "privateIp"
    | "publicIp"
    | "subscription"
    | "userEvents"
    | "version";

export type DateTime_Filter = {
    _null?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type Deployments = "development" | "preview" | "production" | "staging";

export type Deployments_Filter = {
    _eq?: InputMaybe<Deployments>;
};

export type Environment = Node & {
    __typename?: "Environment";
    _meta?: Maybe<SystemMetaData>;
    _partition: Account;
    account: Account;
    /** We really should have a system scalar for color select */
    color?: Maybe<Scalars["String"]["output"]>;
    deleteProtection?: Maybe<Scalars["Boolean"]["output"]>;
    description?: Maybe<Scalars["String"]["output"]>;
    id: Scalars["ID"]["output"];
    locations?: Maybe<Array<Location>>;
    parent?: Maybe<Environment>;
    status: EnvironmentStatus;
};

export type Environment2 = Node & {
    __typename?: "Environment2";
    _meta?: Maybe<SystemMetaData>;
    _partition: Account;
    color?: Maybe<Scalars["String"]["output"]>;
    deleteProtection?: Maybe<Scalars["Boolean"]["output"]>;
    description?: Maybe<Scalars["String"]["output"]>;
    id: Scalars["ID"]["output"];
    lookup: Scalars["String"]["output"];
    name: Scalars["String"]["output"];
    upstream?: Maybe<Environment2>;
};

export type Environment2_Create = {
    color?: InputMaybe<Scalars["String"]["input"]>;
    deleteProtection?: InputMaybe<Scalars["Boolean"]["input"]>;
    description?: InputMaybe<Scalars["String"]["input"]>;
    lookup: Scalars["String"]["input"];
    name: Scalars["String"]["input"];
    upstream?: InputMaybe<NodeReference>;
};

export type Environment2_Filter = {
    _meta?: InputMaybe<SystemMetaData_Filter>;
    _search?: InputMaybe<Scalars["String"]["input"]>;
    color?: InputMaybe<String_Filter>;
    deleteProtection?: InputMaybe<Boolean_Filter>;
    description?: InputMaybe<String_Filter>;
    id?: InputMaybe<Id_Filter>;
    lookup?: InputMaybe<String_Filter>;
    name?: InputMaybe<String_Filter>;
    upstream?: InputMaybe<NodeReference_Filter>;
};

export type Environment2_Lookup = {
    id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type Environment2_Sort = {
    by?: InputMaybe<Array<InputMaybe<Environment2_Sort_Options>>>;
    order?: InputMaybe<Sort>;
};

export type Environment2_Sort_Options =
    | "_createdAt"
    | "_updatedAt"
    | "color"
    | "description"
    | "id"
    | "lookup"
    | "name";

export type Environment2_Update = {
    color?: InputMaybe<Scalars["String"]["input"]>;
    deleteProtection?: InputMaybe<Scalars["Boolean"]["input"]>;
    description?: InputMaybe<Scalars["String"]["input"]>;
    lookup?: InputMaybe<Scalars["String"]["input"]>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    upstream?: InputMaybe<NodeReference>;
};

export type EnvironmentStatus = "ACTIVE" | "PROCESSING";

export type EnvironmentStatus_Filter = {
    _eq?: InputMaybe<EnvironmentStatus>;
};

export type Environment_Filter = {
    _meta?: InputMaybe<SystemMetaData_Filter>;
    _search?: InputMaybe<Scalars["String"]["input"]>;
    account?: InputMaybe<NodeReference_Filter>;
    color?: InputMaybe<String_Filter>;
    deleteProtection?: InputMaybe<Boolean_Filter>;
    description?: InputMaybe<String_Filter>;
    id?: InputMaybe<Id_Filter>;
    parent?: InputMaybe<NodeReference_Filter>;
    status?: InputMaybe<EnvironmentStatus_Filter>;
};

export type Environment_Sort = {
    by?: InputMaybe<Array<InputMaybe<Environment_Sort_Options>>>;
    order?: InputMaybe<Sort>;
};

export type Environment_Sort_Options = "_createdAt" | "_updatedAt" | "color" | "description" | "id";

export type Id_Filter = {
    _eq?: InputMaybe<Scalars["ID"]["input"]>;
    _in?: InputMaybe<Array<Scalars["ID"]["input"]>>;
    _null?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type IamSettings = Node & {
    __typename?: "IamSettings";
    _meta?: Maybe<SystemMetaData>;
    id: Scalars["ID"]["output"];
    message?: Maybe<Scalars["String"]["output"]>;
    oauthEnabled: Scalars["Boolean"]["output"];
    passkeyEnabled: Scalars["Boolean"]["output"];
    pinEnabled: Scalars["Boolean"]["output"];
    trustedDeviceEnabled: Scalars["Boolean"]["output"];
};

export type IamSettings_Lookup = {
    id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type Int_Filter = {
    _eq?: InputMaybe<Scalars["Int"]["input"]>;
    _gt?: InputMaybe<Scalars["Int"]["input"]>;
    _gte?: InputMaybe<Scalars["Int"]["input"]>;
    _lt?: InputMaybe<Scalars["Int"]["input"]>;
    _lte?: InputMaybe<Scalars["Int"]["input"]>;
    _null?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type JsonObject_Filter = {
    _null?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type Location = Node & {
    __typename?: "Location";
    _meta?: Maybe<SystemMetaData>;
    gcp: Scalars["String"]["output"];
    id: Scalars["ID"]["output"];
};

export type Mutation = {
    __typename?: "Mutation";
    activateAuthSession: ActivateAuthSessionResponse;
    confirmAuthSession: AuthSession;
    createAccountUser2: AccountUser2;
    createEmailVerificationCode: Scalars["Boolean"]["output"];
    createEnvironment2: Environment2;
    createProject2: Project2;
    deleteAccount: Account;
    deletePendingEmailVerification: PendingEmailVerification;
    deleteTrustedDevice: TrustedDevice;
    disablePushNotifications: TrustedDevice;
    enablePushNotifications: TrustedDevice;
    loginWithEmailCode: Scalars["Boolean"]["output"];
    loginWithPartnerCode: AuthSession;
    loginWithPasskey: Scalars["Boolean"]["output"];
    logout: Scalars["Boolean"]["output"];
    refreshToken: Scalars["Boolean"]["output"];
    registerAccount: AccountStub;
    registerPartnerCode: Scalars["Boolean"]["output"];
    registerPasskey: Scalars["Boolean"]["output"];
    startAuthSession: AuthSession;
    testPushNotifications: Scalars["Boolean"]["output"];
    unlinkIdentityProvider: Scalars["Boolean"]["output"];
    updateAccountEmailSettings: UserLookup;
    updateAccountUser2: AccountUser2;
    updateEnvironment2: Environment2;
    updateProject2: Project2;
    updateUserProfile: User;
    verifyEmail: Scalars["Boolean"]["output"];
};

export type MutationActivateAuthSessionArgs = {
    sessionId: Scalars["ID"]["input"];
    state?: InputMaybe<AuthSessionState>;
};

export type MutationConfirmAuthSessionArgs = {
    qrcode: Scalars["String"]["input"];
    sessionId: Scalars["ID"]["input"];
};

export type MutationCreateAccountUser2Args = {
    data: AccountUser2_Create;
    options?: InputMaybe<_CreateOptions>;
};

export type MutationCreateEmailVerificationCodeArgs = {
    email: Scalars["String"]["input"];
};

export type MutationCreateEnvironment2Args = {
    data: Environment2_Create;
};

export type MutationCreateProject2Args = {
    data: Project2_Create;
};

export type MutationDeleteAccountArgs = {
    slug: Scalars["String"]["input"];
};

export type MutationDeletePendingEmailVerificationArgs = {
    having: PendingEmailVerification_Lookup;
    options?: InputMaybe<_DeleteOptions>;
};

export type MutationDeleteTrustedDeviceArgs = {
    having: TrustedDevice_Lookup;
    options?: InputMaybe<_DeleteOptions>;
};

export type MutationDisablePushNotificationsArgs = {
    deviceId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type MutationEnablePushNotificationsArgs = {
    auth: Scalars["String"]["input"];
    endpoint: Scalars["String"]["input"];
    expirationTime?: InputMaybe<Scalars["String"]["input"]>;
    p256dh: Scalars["String"]["input"];
};

export type MutationLoginWithEmailCodeArgs = {
    code: Scalars["String"]["input"];
    sessionId: Scalars["ID"]["input"];
};

export type MutationLoginWithPartnerCodeArgs = {
    code: Scalars["String"]["input"];
    provider: Scalars["String"]["input"];
    referrer: Scalars["String"]["input"];
    rememberMe?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationLoginWithPasskeyArgs = {
    authentication: Scalars["JSONObject"]["input"];
    referrer: Scalars["String"]["input"];
    rememberMe?: InputMaybe<Scalars["Boolean"]["input"]>;
    sessionId: Scalars["ID"]["input"];
};

export type MutationRegisterAccountArgs = {
    name: Scalars["String"]["input"];
    slug: Scalars["String"]["input"];
};

export type MutationRegisterPartnerCodeArgs = {
    code: Scalars["String"]["input"];
    provider: Scalars["String"]["input"];
    referrer: Scalars["String"]["input"];
};

export type MutationRegisterPasskeyArgs = {
    referrer: Scalars["String"]["input"];
    registration: Scalars["JSONObject"]["input"];
    sessionId: Scalars["ID"]["input"];
};

export type MutationStartAuthSessionArgs = {
    email?: InputMaybe<Scalars["String"]["input"]>;
    rememberMe?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationTestPushNotificationsArgs = {
    deviceId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type MutationUnlinkIdentityProviderArgs = {
    providerId: Scalars["ID"]["input"];
};

export type MutationUpdateAccountEmailSettingsArgs = {
    email: Scalars["String"]["input"];
    pinEnabled?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationUpdateAccountUser2Args = {
    having: AccountUser2_Lookup;
    options?: InputMaybe<_UpdateOptions>;
    set: AccountUser2_Update;
};

export type MutationUpdateEnvironment2Args = {
    having: Environment2_Lookup;
    options?: InputMaybe<_UpdateOptions>;
    set: Environment2_Update;
};

export type MutationUpdateProject2Args = {
    having: Project2_Lookup;
    options?: InputMaybe<_UpdateOptions>;
    set: Project2_Update;
};

export type MutationUpdateUserProfileArgs = {
    firstName: Scalars["String"]["input"];
    lastName: Scalars["String"]["input"];
    primaryEmail: Scalars["String"]["input"];
    profilePicture?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationVerifyEmailArgs = {
    code: Scalars["String"]["input"];
    email: Scalars["String"]["input"];
};

export type Node = {
    _meta?: Maybe<SystemMetaData>;
    id: Scalars["ID"]["output"];
};

export type NodeReference = {
    id: Scalars["ID"]["input"];
};

export type NodeReference_Filter = {
    _meta?: InputMaybe<SystemMetaData_Filter>;
    id?: InputMaybe<Id_Filter>;
};

export type OAuthProvider2 = Node & {
    __typename?: "OAuthProvider2";
    _meta?: Maybe<SystemMetaData>;
    authenticator: Authenticator;
    authorizationEndpoint: Scalars["String"]["output"];
    clientId: Scalars["String"]["output"];
    clientSecret: Scalars["String"]["output"];
    configurationEndpoint?: Maybe<Scalars["String"]["output"]>;
    enabled: Array<Deployments>;
    id: Scalars["ID"]["output"];
    params: Array<OAuthProvider2Params>;
    sort?: Maybe<Scalars["Int"]["output"]>;
    tokenEndpoint: Scalars["String"]["output"];
    userinfoEndpoint?: Maybe<Scalars["String"]["output"]>;
};

export type OAuthProvider2Params = {
    __typename?: "OAuthProvider2Params";
    key: Scalars["String"]["output"];
    value: Scalars["String"]["output"];
};

export type OAuthProvider2Params_Filter = {
    key?: InputMaybe<String_Filter>;
    value?: InputMaybe<String_Filter>;
};

export type OAuthProvider2_Filter = {
    _meta?: InputMaybe<SystemMetaData_Filter>;
    _search?: InputMaybe<Scalars["String"]["input"]>;
    authenticator?: InputMaybe<NodeReference_Filter>;
    authorizationEndpoint?: InputMaybe<String_Filter>;
    clientId?: InputMaybe<String_Filter>;
    clientSecret?: InputMaybe<String_Filter>;
    configurationEndpoint?: InputMaybe<String_Filter>;
    id?: InputMaybe<Id_Filter>;
    sort?: InputMaybe<Int_Filter>;
    tokenEndpoint?: InputMaybe<String_Filter>;
    userinfoEndpoint?: InputMaybe<String_Filter>;
};

export type OAuthProvider2_Sort = {
    by?: InputMaybe<Array<InputMaybe<OAuthProvider2_Sort_Options>>>;
    order?: InputMaybe<Sort>;
};

export type OAuthProvider2_Sort_Options =
    | "_createdAt"
    | "_updatedAt"
    | "authorizationEndpoint"
    | "clientId"
    | "clientSecret"
    | "configurationEndpoint"
    | "id"
    | "key"
    | "sort"
    | "tokenEndpoint"
    | "userinfoEndpoint"
    | "value";

export type Overlay = Node & {
    __typename?: "Overlay";
    _meta?: Maybe<SystemMetaData>;
    _partition: Account;
    account: Account;
    approved?: Maybe<Scalars["Boolean"]["output"]>;
    environment: Environment;
    id: Scalars["ID"]["output"];
    keepOpen?: Maybe<Scalars["Boolean"]["output"]>;
    projects?: Maybe<Array<Project>>;
    schemaConsistency?: Maybe<Scalars["Boolean"]["output"]>;
    services?: Maybe<Array<OverlayServiceUpdate>>;
    slug: Scalars["ID"]["output"];
    status?: Maybe<OverlayStatus>;
    statusTime?: Maybe<Scalars["String"]["output"]>;
};

export type OverlayServiceUpdate = {
    __typename?: "OverlayServiceUpdate";
    service: Scalars["String"]["output"];
    version: Scalars["String"]["output"];
};

export type OverlayServiceUpdate_Filter = {
    service?: InputMaybe<String_Filter>;
    version?: InputMaybe<String_Filter>;
};

export type OverlayStatus = "CLOSED" | "COMMIT" | "OPEN" | "ROLLBACK";

export type OverlayStatus_Filter = {
    _eq?: InputMaybe<OverlayStatus>;
};

export type Overlay_Filter = {
    _meta?: InputMaybe<SystemMetaData_Filter>;
    _search?: InputMaybe<Scalars["String"]["input"]>;
    account?: InputMaybe<NodeReference_Filter>;
    approved?: InputMaybe<Boolean_Filter>;
    environment?: InputMaybe<NodeReference_Filter>;
    id?: InputMaybe<Id_Filter>;
    keepOpen?: InputMaybe<Boolean_Filter>;
    schemaConsistency?: InputMaybe<Boolean_Filter>;
    slug?: InputMaybe<Id_Filter>;
    status?: InputMaybe<OverlayStatus_Filter>;
    statusTime?: InputMaybe<DateTime_Filter>;
};

export type Overlay_Sort = {
    by?: InputMaybe<Array<InputMaybe<Overlay_Sort_Options>>>;
    order?: InputMaybe<Sort>;
};

export type Overlay_Sort_Options = "_createdAt" | "_updatedAt" | "id" | "service" | "slug" | "version";

export type Package = Node & {
    __typename?: "Package";
    _meta?: Maybe<SystemMetaData>;
    _partition: Account;
    account: Account;
    channels?: Maybe<Array<PackageChannel>>;
    cloudManager?: Maybe<Scalars["String"]["output"]>;
    description?: Maybe<Scalars["String"]["output"]>;
    id: Scalars["ID"]["output"];
    locations?: Maybe<Array<Location>>;
    name: Scalars["ID"]["output"];
    service?: Maybe<Scalars["String"]["output"]>;
    services?: Maybe<Array<PackageLocation>>;
    trafficChannel?: Maybe<Scalars["String"]["output"]>;
    versions?: Maybe<Array<PackageVersion>>;
};

export type PackageServicesArgs = {
    after?: InputMaybe<Scalars["String"]["input"]>;
    filter?: InputMaybe<PackageLocation_Filter>;
    first?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<PackageLocation_Sort>;
};

export type PackageVersionsArgs = {
    after?: InputMaybe<Scalars["String"]["input"]>;
    filter?: InputMaybe<PackageVersion_Filter>;
    first?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<PackageVersion_Sort>;
};

export type PackageChannel = {
    __typename?: "PackageChannel";
    channel: Scalars["String"]["output"];
    version: PackageVersion;
};

export type PackageChannel_Filter = {
    channel?: InputMaybe<String_Filter>;
    version?: InputMaybe<NodeReference_Filter>;
};

export type PackageDependency = {
    __typename?: "PackageDependency";
    package: Scalars["String"]["output"];
    version: Scalars["String"]["output"];
};

export type PackageDependency_Filter = {
    package?: InputMaybe<String_Filter>;
    version?: InputMaybe<String_Filter>;
};

export type PackageDistribution = {
    __typename?: "PackageDistribution";
    fileCount?: Maybe<Scalars["Int"]["output"]>;
    integrity: Scalars["String"]["output"];
    shasum: Scalars["String"]["output"];
    unpackedSize?: Maybe<Scalars["Int"]["output"]>;
};

export type PackageDistribution_Filter = {
    fileCount?: InputMaybe<Int_Filter>;
    integrity?: InputMaybe<String_Filter>;
    shasum?: InputMaybe<String_Filter>;
    unpackedSize?: InputMaybe<Int_Filter>;
};

export type PackageLocation = Node & {
    __typename?: "PackageLocation";
    _meta?: Maybe<SystemMetaData>;
    _partition: Account;
    id: Scalars["ID"]["output"];
    location: Location;
    package: Package;
    url: Scalars["String"]["output"];
};

export type PackageLocation_Filter = {
    _meta?: InputMaybe<SystemMetaData_Filter>;
    _search?: InputMaybe<Scalars["String"]["input"]>;
    id?: InputMaybe<Id_Filter>;
    location?: InputMaybe<NodeReference_Filter>;
    package?: InputMaybe<PackageLocation_Filter_Package>;
    url?: InputMaybe<String_Filter>;
};

export type PackageLocation_Filter_Package = {
    _meta?: InputMaybe<SystemMetaData_Filter>;
    account?: InputMaybe<PackageLocation_Filter_Package_Account>;
    id?: InputMaybe<Id_Filter>;
    name?: InputMaybe<Id_Filter>;
};

export type PackageLocation_Filter_Package_Account = {
    _meta?: InputMaybe<SystemMetaData_Filter>;
    id?: InputMaybe<Id_Filter>;
};

export type PackageLocation_Sort = {
    by?: InputMaybe<Array<InputMaybe<PackageLocation_Sort_Options>>>;
    order?: InputMaybe<Sort>;
};

export type PackageLocation_Sort_Options =
    | "_createdAt"
    | "_updatedAt"
    | "id"
    | "package__account__id"
    | "package__id"
    | "package__name"
    | "url";

export type PackageRepository = {
    __typename?: "PackageRepository";
    namespace?: Maybe<Scalars["String"]["output"]>;
    repository?: Maybe<Scalars["String"]["output"]>;
    token: Scalars["String"]["output"];
};

export type PackageRepository_Filter = {
    namespace?: InputMaybe<String_Filter>;
    repository?: InputMaybe<String_Filter>;
    token?: InputMaybe<String_Filter>;
};

export type PackageSettings = Node & {
    __typename?: "PackageSettings";
    _meta?: Maybe<SystemMetaData>;
    _partition: Account;
    account: Account;
    id: Scalars["ID"]["output"];
    repositories?: Maybe<Array<PackageRepository>>;
};

export type PackageSettings_Filter = {
    _meta?: InputMaybe<SystemMetaData_Filter>;
    _search?: InputMaybe<Scalars["String"]["input"]>;
    account?: InputMaybe<NodeReference_Filter>;
    id?: InputMaybe<Id_Filter>;
};

export type PackageSettings_Sort = {
    by?: InputMaybe<Array<InputMaybe<PackageSettings_Sort_Options>>>;
    order?: InputMaybe<Sort>;
};

export type PackageSettings_Sort_Options = "_createdAt" | "_updatedAt" | "id" | "namespace" | "repository" | "token";

export type PackageVersion = Node & {
    __typename?: "PackageVersion";
    _meta?: Maybe<SystemMetaData>;
    _partition: Account;
    dependencies?: Maybe<Array<PackageDependency>>;
    dist?: Maybe<PackageDistribution>;
    docker?: Maybe<Scalars["String"]["output"]>;
    id: Scalars["ID"]["output"];
    package: Package;
    status?: Maybe<PackageVersionStatus>;
    tar?: Maybe<StackablesUpload>;
    version: Scalars["ID"]["output"];
};

export type PackageVersionStatus = "active" | "building" | "delete" | "published";

export type PackageVersionStatus_Filter = {
    _eq?: InputMaybe<PackageVersionStatus>;
};

export type PackageVersion_Filter = {
    _meta?: InputMaybe<SystemMetaData_Filter>;
    _search?: InputMaybe<Scalars["String"]["input"]>;
    dist?: InputMaybe<PackageDistribution_Filter>;
    docker?: InputMaybe<String_Filter>;
    id?: InputMaybe<Id_Filter>;
    package?: InputMaybe<PackageVersion_Filter_Package>;
    status?: InputMaybe<PackageVersionStatus_Filter>;
    tar?: InputMaybe<StackablesUpload_Filter>;
    version?: InputMaybe<Id_Filter>;
};

export type PackageVersion_Filter_Package = {
    _meta?: InputMaybe<SystemMetaData_Filter>;
    id?: InputMaybe<Id_Filter>;
    name?: InputMaybe<Id_Filter>;
};

export type PackageVersion_Sort = {
    by?: InputMaybe<Array<InputMaybe<PackageVersion_Sort_Options>>>;
    order?: InputMaybe<Sort>;
};

export type PackageVersion_Sort_Options =
    | "_createdAt"
    | "_updatedAt"
    | "docker"
    | "fileCount"
    | "id"
    | "integrity"
    | "package"
    | "package__id"
    | "package__name"
    | "shasum"
    | "unpackedSize"
    | "version";

export type Package_Filter = {
    _meta?: InputMaybe<SystemMetaData_Filter>;
    _search?: InputMaybe<Scalars["String"]["input"]>;
    account?: InputMaybe<Package_Filter_Account>;
    cloudManager?: InputMaybe<String_Filter>;
    description?: InputMaybe<String_Filter>;
    id?: InputMaybe<Id_Filter>;
    name?: InputMaybe<Id_Filter>;
    service?: InputMaybe<String_Filter>;
    trafficChannel?: InputMaybe<String_Filter>;
};

export type Package_Filter_Account = {
    _meta?: InputMaybe<SystemMetaData_Filter>;
    id?: InputMaybe<Id_Filter>;
    slug?: InputMaybe<String_Filter>;
};

export type Package_Sort = {
    by?: InputMaybe<Array<InputMaybe<Package_Sort_Options>>>;
    order?: InputMaybe<Sort>;
};

export type Package_Sort_Options =
    | "_createdAt"
    | "_updatedAt"
    | "account__id"
    | "account__slug"
    | "channel"
    | "cloudManager"
    | "description"
    | "id"
    | "name"
    | "service"
    | "trafficChannel";

export type PendingEmailVerification = Node & {
    __typename?: "PendingEmailVerification";
    _meta?: Maybe<SystemMetaData>;
    _partition: User;
    codeEmail: Scalars["String"]["output"];
    /** @deprecated do not use this field */
    completed?: Maybe<Scalars["Boolean"]["output"]>;
    email: Scalars["String"]["output"];
    id: Scalars["ID"]["output"];
    /** @deprecated do not use this field */
    user?: Maybe<User>;
};

export type PendingEmailVerification_Filter = {
    _meta?: InputMaybe<SystemMetaData_Filter>;
    _search?: InputMaybe<Scalars["String"]["input"]>;
    codeEmail?: InputMaybe<String_Filter>;
    completed?: InputMaybe<Boolean_Filter>;
    email?: InputMaybe<String_Filter>;
    id?: InputMaybe<Id_Filter>;
    user?: InputMaybe<NodeReference_Filter>;
};

export type PendingEmailVerification_Lookup = {
    id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type PendingEmailVerification_Sort = {
    by?: InputMaybe<Array<InputMaybe<PendingEmailVerification_Sort_Options>>>;
    order?: InputMaybe<Sort>;
};

export type PendingEmailVerification_Sort_Options = "_createdAt" | "_updatedAt" | "codeEmail" | "email" | "id";

export type Project = Node & {
    __typename?: "Project";
    _meta?: Maybe<SystemMetaData>;
    _partition: Account;
    account: Account;
    /** More info about the project for internal users */
    description?: Maybe<Scalars["String"]["output"]>;
    id: Scalars["ID"]["output"];
    slug: Scalars["ID"]["output"];
    storages?: Maybe<Array<Storage>>;
};

export type ProjectStoragesArgs = {
    after?: InputMaybe<Scalars["String"]["input"]>;
    filter?: InputMaybe<Storage_Filter>;
    first?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<Storage_Sort>;
};

export type Project2 = Node & {
    __typename?: "Project2";
    _meta?: Maybe<SystemMetaData>;
    _partition: Account;
    id: Scalars["ID"]["output"];
    lookup: Scalars["String"]["output"];
    name: Scalars["String"]["output"];
    type: Project2Type;
};

export type Project2Type = "c" | "d";

export type Project2Type_Filter = {
    _eq?: InputMaybe<Project2Type>;
};

export type Project2_Create = {
    lookup: Scalars["String"]["input"];
    name: Scalars["String"]["input"];
    type: Project2Type;
};

export type Project2_Filter = {
    _meta?: InputMaybe<SystemMetaData_Filter>;
    _search?: InputMaybe<Scalars["String"]["input"]>;
    id?: InputMaybe<Id_Filter>;
    lookup?: InputMaybe<String_Filter>;
    name?: InputMaybe<String_Filter>;
    type?: InputMaybe<Project2Type_Filter>;
};

export type Project2_Lookup = {
    id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type Project2_Sort = {
    by?: InputMaybe<Array<InputMaybe<Project2_Sort_Options>>>;
    order?: InputMaybe<Sort>;
};

export type Project2_Sort_Options = "_createdAt" | "_updatedAt" | "id" | "lookup" | "name";

export type Project2_Update = {
    lookup?: InputMaybe<Scalars["String"]["input"]>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    type?: InputMaybe<Project2Type>;
};

export type ProjectStub = {
    __typename?: "ProjectStub";
    id: Scalars["ID"]["output"];
    lookup: Scalars["String"]["output"];
    name: Scalars["String"]["output"];
    type: ProjectStubType;
};

export type ProjectStubType = "c" | "d";

export type Project_Filter = {
    _meta?: InputMaybe<SystemMetaData_Filter>;
    _search?: InputMaybe<Scalars["String"]["input"]>;
    account?: InputMaybe<NodeReference_Filter>;
    description?: InputMaybe<String_Filter>;
    id?: InputMaybe<Id_Filter>;
    slug?: InputMaybe<Id_Filter>;
};

export type Project_Sort = {
    by?: InputMaybe<Array<InputMaybe<Project_Sort_Options>>>;
    order?: InputMaybe<Sort>;
};

export type Project_Sort_Options = "_createdAt" | "_updatedAt" | "description" | "id" | "slug";

export type Query = {
    __typename?: "Query";
    fetchAccount?: Maybe<Account>;
    fetchAccountUser2?: Maybe<AccountUser2>;
    fetchAuthSession: AuthSession;
    fetchEnvironment2?: Maybe<Environment2>;
    fetchIamSettings?: Maybe<IamSettings>;
    fetchPendingEmailVerification?: Maybe<PendingEmailVerification>;
    fetchProject2?: Maybe<Project2>;
    fetchUser?: Maybe<User>;
    listAccountUser2s?: Maybe<Array<AccountUser2>>;
    listCollections?: Maybe<Array<_Collection>>;
    listEnvironment2s?: Maybe<Array<Environment2>>;
    listOAuthProvider2s?: Maybe<Array<OAuthProvider2>>;
    listPendingEmailVerifications?: Maybe<Array<PendingEmailVerification>>;
    listProject2s?: Maybe<Array<Project2>>;
    listSystemTypes?: Maybe<Array<_SystemType>>;
    listTrustedDevices?: Maybe<Array<TrustedDevice>>;
    listUserAccounts: Array<AccountStub>;
    testAccount: Scalars["Boolean"]["output"];
};

export type QueryFetchAccountUser2Args = {
    by: AccountUser2_Lookup;
};

export type QueryFetchAuthSessionArgs = {
    sessionId: Scalars["ID"]["input"];
};

export type QueryFetchEnvironment2Args = {
    by: Environment2_Lookup;
};

export type QueryFetchIamSettingsArgs = {
    by: IamSettings_Lookup;
};

export type QueryFetchPendingEmailVerificationArgs = {
    by: PendingEmailVerification_Lookup;
};

export type QueryFetchProject2Args = {
    by: Project2_Lookup;
};

export type QueryListAccountUser2sArgs = {
    after?: InputMaybe<Scalars["String"]["input"]>;
    filter?: InputMaybe<AccountUser2_Filter>;
    first?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<AccountUser2_Sort>;
};

export type QueryListCollectionsArgs = {
    after?: InputMaybe<Scalars["String"]["input"]>;
    filter?: InputMaybe<_Collection_Filter>;
    first?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<_Collection_Sort>;
};

export type QueryListEnvironment2sArgs = {
    after?: InputMaybe<Scalars["String"]["input"]>;
    filter?: InputMaybe<Environment2_Filter>;
    first?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<Environment2_Sort>;
};

export type QueryListOAuthProvider2sArgs = {
    after?: InputMaybe<Scalars["String"]["input"]>;
    filter?: InputMaybe<OAuthProvider2_Filter>;
    first?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<OAuthProvider2_Sort>;
};

export type QueryListPendingEmailVerificationsArgs = {
    after?: InputMaybe<Scalars["String"]["input"]>;
    filter?: InputMaybe<PendingEmailVerification_Filter>;
    first?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<PendingEmailVerification_Sort>;
};

export type QueryListProject2sArgs = {
    after?: InputMaybe<Scalars["String"]["input"]>;
    filter?: InputMaybe<Project2_Filter>;
    first?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<Project2_Sort>;
};

export type QueryListSystemTypesArgs = {
    after?: InputMaybe<Scalars["String"]["input"]>;
    filter?: InputMaybe<_SystemType_Filter>;
    first?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<_SystemType_Sort>;
};

export type QueryListTrustedDevicesArgs = {
    after?: InputMaybe<Scalars["String"]["input"]>;
    filter?: InputMaybe<TrustedDevice_Filter>;
    first?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<TrustedDevice_Sort>;
};

export type QueryTestAccountArgs = {
    slug: Scalars["String"]["input"];
};

export type RefreshToken = Node & {
    __typename?: "RefreshToken";
    _meta?: Maybe<SystemMetaData>;
    _partition: User;
    /** @deprecated Do not use this field */
    deviceInfo?: Maybe<RefreshTokenDeviceInfo>;
    id: Scalars["ID"]["output"];
    /** @deprecated Do not use this field */
    push?: Maybe<RefreshTokenPush>;
    refreshCount: Scalars["Int"]["output"];
    /** @deprecated Do not use this field */
    sessionInfo?: Maybe<RefreshTokenSessionInfo>;
    usageTracking?: Maybe<Scalars["JSONObject"]["output"]>;
    /** @deprecated Do not use this field */
    user?: Maybe<User>;
    /** @deprecated Do not use this field */
    userLookup?: Maybe<UserLookup>;
};

export type RefreshTokenDeviceInfo = {
    __typename?: "RefreshTokenDeviceInfo";
    browser?: Maybe<Scalars["String"]["output"]>;
    deviceId?: Maybe<Scalars["String"]["output"]>;
    fingerprint?: Maybe<Scalars["String"]["output"]>;
    model?: Maybe<Scalars["String"]["output"]>;
    os?: Maybe<Scalars["String"]["output"]>;
    type?: Maybe<Scalars["String"]["output"]>;
    userAgent?: Maybe<Scalars["String"]["output"]>;
    vendor?: Maybe<Scalars["String"]["output"]>;
};

export type RefreshTokenDeviceInfo_Filter = {
    browser?: InputMaybe<String_Filter>;
    deviceId?: InputMaybe<String_Filter>;
    fingerprint?: InputMaybe<String_Filter>;
    model?: InputMaybe<String_Filter>;
    os?: InputMaybe<String_Filter>;
    type?: InputMaybe<String_Filter>;
    userAgent?: InputMaybe<String_Filter>;
    vendor?: InputMaybe<String_Filter>;
};

export type RefreshTokenPush = {
    __typename?: "RefreshTokenPush";
    endpoint: Scalars["String"]["output"];
    expirationTime?: Maybe<Scalars["String"]["output"]>;
    keys: RefreshTokenPushKeys;
};

export type RefreshTokenPushKeys = {
    __typename?: "RefreshTokenPushKeys";
    auth: Scalars["String"]["output"];
    p256dh: Scalars["String"]["output"];
};

export type RefreshTokenPushKeys_Filter = {
    auth?: InputMaybe<String_Filter>;
    p256dh?: InputMaybe<String_Filter>;
};

export type RefreshTokenPush_Filter = {
    endpoint?: InputMaybe<String_Filter>;
    expirationTime?: InputMaybe<DateTime_Filter>;
    keys?: InputMaybe<RefreshTokenPushKeys_Filter>;
};

export type RefreshTokenSessionInfo = {
    __typename?: "RefreshTokenSessionInfo";
    city?: Maybe<Scalars["String"]["output"]>;
    ip?: Maybe<Scalars["String"]["output"]>;
    lastUsed?: Maybe<Scalars["String"]["output"]>;
    region?: Maybe<Scalars["String"]["output"]>;
};

export type RefreshTokenSessionInfo_Filter = {
    city?: InputMaybe<String_Filter>;
    ip?: InputMaybe<String_Filter>;
    lastUsed?: InputMaybe<DateTime_Filter>;
    region?: InputMaybe<String_Filter>;
};

export type RefreshToken_Filter = {
    _meta?: InputMaybe<SystemMetaData_Filter>;
    _search?: InputMaybe<Scalars["String"]["input"]>;
    deviceInfo?: InputMaybe<RefreshTokenDeviceInfo_Filter>;
    id?: InputMaybe<Id_Filter>;
    push?: InputMaybe<RefreshTokenPush_Filter>;
    refreshCount?: InputMaybe<Int_Filter>;
    sessionInfo?: InputMaybe<RefreshTokenSessionInfo_Filter>;
    usageTracking?: InputMaybe<JsonObject_Filter>;
    user?: InputMaybe<NodeReference_Filter>;
    userLookup?: InputMaybe<NodeReference_Filter>;
};

export type RefreshToken_Sort = {
    by?: InputMaybe<Array<InputMaybe<RefreshToken_Sort_Options>>>;
    order?: InputMaybe<Sort>;
};

export type RefreshToken_Sort_Options =
    | "_createdAt"
    | "_updatedAt"
    | "auth"
    | "browser"
    | "city"
    | "deviceId"
    | "endpoint"
    | "fingerprint"
    | "id"
    | "ip"
    | "model"
    | "os"
    | "p256dh"
    | "refreshCount"
    | "region"
    | "type"
    | "userAgent"
    | "vendor";

export type ResourceGroup = Node & {
    __typename?: "ResourceGroup";
    _meta?: Maybe<SystemMetaData>;
    databases?: Maybe<Array<Database>>;
    id: Scalars["ID"]["output"];
    ip?: Maybe<Scalars["String"]["output"]>;
    name: Scalars["String"]["output"];
    port?: Maybe<Scalars["Int"]["output"]>;
    private?: Maybe<Account>;
};

export type ResourceGroupDatabasesArgs = {
    after?: InputMaybe<Scalars["String"]["input"]>;
    filter?: InputMaybe<Database_Filter>;
    first?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<Database_Sort>;
};

export type Sort = "asc" | "desc";

export type StackablesUpload = {
    __typename?: "StackablesUpload";
    attachmentId?: Maybe<Scalars["String"]["output"]>;
    encoding?: Maybe<Scalars["String"]["output"]>;
    filename?: Maybe<Scalars["String"]["output"]>;
    mimetype?: Maybe<Scalars["String"]["output"]>;
    url?: Maybe<StackablesUploadUrl>;
};

export type StackablesUploadUrl = {
    __typename?: "StackablesUploadUrl";
    signed?: Maybe<Scalars["String"]["output"]>;
};

export type StackablesUpload_Filter = {
    _null?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type Storage = Node & {
    __typename?: "Storage";
    _meta?: Maybe<SystemMetaData>;
    _partition: Account;
    account: Account;
    collection: Scalars["String"]["output"];
    coreVersion?: Maybe<Scalars["Int"]["output"]>;
    databases: Array<Database>;
    environment: Environment;
    id: Scalars["ID"]["output"];
    operations?: Maybe<Array<StorageOperation>>;
    project: Project;
    redeploy?: Maybe<Scalars["Boolean"]["output"]>;
    schema: Scalars["String"]["output"];
    shardEnd?: Maybe<Scalars["Int"]["output"]>;
    shardStart?: Maybe<Scalars["Int"]["output"]>;
    /** @deprecated Not currently used */
    status?: Maybe<Scalars["String"]["output"]>;
    summary?: Maybe<Array<StorageSummary>>;
    tableVersion?: Maybe<Scalars["Int"]["output"]>;
    /** @deprecated Not currently used */
    version?: Maybe<Scalars["Int"]["output"]>;
};

export type StorageOperation = {
    order?: Maybe<Scalars["Int"]["output"]>;
};

export type StorageOperationAdd = StorageOperation & {
    __typename?: "StorageOperationAdd";
    copyFrom?: Maybe<Database>;
    database: Database;
    order?: Maybe<Scalars["Int"]["output"]>;
};

export type StorageOperationCreate = StorageOperation & {
    __typename?: "StorageOperationCreate";
    databases: Array<Database>;
    order?: Maybe<Scalars["Int"]["output"]>;
};

export type StorageSummary = {
    __typename?: "StorageSummary";
    count?: Maybe<Scalars["Int"]["output"]>;
    database: Database;
    hash?: Maybe<Scalars["String"]["output"]>;
    latest?: Maybe<Scalars["String"]["output"]>;
    type?: Maybe<Scalars["String"]["output"]>;
};

export type Storage_Filter = {
    _meta?: InputMaybe<SystemMetaData_Filter>;
    _search?: InputMaybe<Scalars["String"]["input"]>;
    account?: InputMaybe<NodeReference_Filter>;
    collection?: InputMaybe<String_Filter>;
    coreVersion?: InputMaybe<Int_Filter>;
    environment?: InputMaybe<Storage_Filter_Environment>;
    id?: InputMaybe<Id_Filter>;
    project?: InputMaybe<Storage_Filter_Project>;
    redeploy?: InputMaybe<Boolean_Filter>;
    schema?: InputMaybe<String_Filter>;
    shardEnd?: InputMaybe<Int_Filter>;
    shardStart?: InputMaybe<Int_Filter>;
    status?: InputMaybe<String_Filter>;
    tableVersion?: InputMaybe<Int_Filter>;
};

export type Storage_Filter_Environment = {
    _meta?: InputMaybe<SystemMetaData_Filter>;
    id?: InputMaybe<Id_Filter>;
};

export type Storage_Filter_Project = {
    _meta?: InputMaybe<SystemMetaData_Filter>;
    id?: InputMaybe<Id_Filter>;
    slug?: InputMaybe<Id_Filter>;
};

export type Storage_Sort = {
    by?: InputMaybe<Array<InputMaybe<Storage_Sort_Options>>>;
    order?: InputMaybe<Sort>;
};

export type Storage_Sort_Options =
    | "_createdAt"
    | "_updatedAt"
    | "collection"
    | "coreVersion"
    | "environment__id"
    | "id"
    | "project__id"
    | "project__slug"
    | "schema"
    | "shardEnd"
    | "shardStart"
    | "status"
    | "tableVersion";

export type String_Filter = {
    _eq?: InputMaybe<Scalars["String"]["input"]>;
    _like?: InputMaybe<Scalars["String"]["input"]>;
    _neq?: InputMaybe<Scalars["String"]["input"]>;
    _null?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type SystemMetaData = {
    __typename?: "SystemMetaData";
    createdAt?: Maybe<Scalars["String"]["output"]>;
    cursor?: Maybe<Scalars["String"]["output"]>;
    dataVersion?: Maybe<Scalars["String"]["output"]>;
    deletedAt?: Maybe<Scalars["String"]["output"]>;
    eTag?: Maybe<Scalars["String"]["output"]>;
    externalId?: Maybe<Scalars["String"]["output"]>;
    guid?: Maybe<Scalars["ID"]["output"]>;
    history?: Maybe<Array<SystemMetaDataHistory>>;
    overlay?: Maybe<Scalars["String"]["output"]>;
    partition?: Maybe<Scalars["ID"]["output"]>;
    puid?: Maybe<Scalars["ID"]["output"]>;
    shard?: Maybe<Scalars["Int"]["output"]>;
    updatedAt?: Maybe<Scalars["String"]["output"]>;
    updatedBy?: Maybe<Scalars["String"]["output"]>;
};

export type SystemMetaDataHistory = {
    __typename?: "SystemMetaDataHistory";
    eTag?: Maybe<Scalars["String"]["output"]>;
    patch?: Maybe<Array<SystemMetaDataHistoryChange>>;
};

export type SystemMetaDataHistoryChange = {
    __typename?: "SystemMetaDataHistoryChange";
    op?: Maybe<Scalars["String"]["output"]>;
    path?: Maybe<Scalars["String"]["output"]>;
    value?: Maybe<Scalars["String"]["output"]>;
};

export type SystemMetaData_Filter = {
    guid?: InputMaybe<Scalars["ID"]["input"]>;
    puid?: InputMaybe<Scalars["ID"]["input"]>;
};

export type Team = Node & {
    __typename?: "Team";
    _meta?: Maybe<SystemMetaData>;
    _partition: Account;
    account: Account;
    description?: Maybe<Scalars["String"]["output"]>;
    id: Scalars["ID"]["output"];
    members?: Maybe<Array<TeamMember>>;
    team: Scalars["ID"]["output"];
};

export type TeamMembersArgs = {
    after?: InputMaybe<Scalars["String"]["input"]>;
    filter?: InputMaybe<TeamMember_Filter>;
    first?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<TeamMember_Sort>;
};

export type TeamMember = Node & {
    __typename?: "TeamMember";
    _meta?: Maybe<SystemMetaData>;
    _partition: Account;
    account: Account;
    id: Scalars["ID"]["output"];
    team: Team;
    user: AccountUser;
};

export type TeamMember_Filter = {
    _meta?: InputMaybe<SystemMetaData_Filter>;
    _search?: InputMaybe<Scalars["String"]["input"]>;
    account?: InputMaybe<NodeReference_Filter>;
    id?: InputMaybe<Id_Filter>;
    team?: InputMaybe<NodeReference_Filter>;
    user?: InputMaybe<NodeReference_Filter>;
};

export type TeamMember_Sort = {
    by?: InputMaybe<Array<InputMaybe<TeamMember_Sort_Options>>>;
    order?: InputMaybe<Sort>;
};

export type TeamMember_Sort_Options = "_createdAt" | "_updatedAt" | "id";

export type Team_Filter = {
    _meta?: InputMaybe<SystemMetaData_Filter>;
    _search?: InputMaybe<Scalars["String"]["input"]>;
    account?: InputMaybe<NodeReference_Filter>;
    description?: InputMaybe<String_Filter>;
    id?: InputMaybe<Id_Filter>;
    team?: InputMaybe<Id_Filter>;
};

export type Team_Sort = {
    by?: InputMaybe<Array<InputMaybe<Team_Sort_Options>>>;
    order?: InputMaybe<Sort>;
};

export type Team_Sort_Options = "_createdAt" | "_updatedAt" | "description" | "id" | "team";

export type TrustedDevice = Node & {
    __typename?: "TrustedDevice";
    _meta?: Maybe<SystemMetaData>;
    _partition: User;
    currentSession?: Maybe<Scalars["Boolean"]["output"]>;
    id: Scalars["ID"]["output"];
    push?: Maybe<TrustedDevicePush>;
    pushEnabled?: Maybe<Scalars["Boolean"]["output"]>;
    refreshCount: Scalars["Int"]["output"];
    usageInfo?: Maybe<UsageInfo>;
    usageTracking?: Maybe<Scalars["JSONObject"]["output"]>;
};

export type TrustedDevicePush = {
    __typename?: "TrustedDevicePush";
    endpoint: Scalars["String"]["output"];
    expirationTime?: Maybe<Scalars["String"]["output"]>;
    keys: TrustedDevicePushKeys;
};

export type TrustedDevicePushKeys = {
    __typename?: "TrustedDevicePushKeys";
    auth: Scalars["String"]["output"];
    p256dh: Scalars["String"]["output"];
};

export type TrustedDevicePushKeys_Filter = {
    auth?: InputMaybe<String_Filter>;
    p256dh?: InputMaybe<String_Filter>;
};

export type TrustedDevicePush_Filter = {
    endpoint?: InputMaybe<String_Filter>;
    expirationTime?: InputMaybe<String_Filter>;
    keys?: InputMaybe<TrustedDevicePushKeys_Filter>;
};

export type TrustedDevice_Filter = {
    _meta?: InputMaybe<SystemMetaData_Filter>;
    _search?: InputMaybe<Scalars["String"]["input"]>;
    id?: InputMaybe<Id_Filter>;
    push?: InputMaybe<TrustedDevicePush_Filter>;
    refreshCount?: InputMaybe<Int_Filter>;
    usageTracking?: InputMaybe<JsonObject_Filter>;
};

export type TrustedDevice_Lookup = {
    id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type TrustedDevice_Sort = {
    by?: InputMaybe<Array<InputMaybe<TrustedDevice_Sort_Options>>>;
    order?: InputMaybe<Sort>;
};

export type TrustedDevice_Sort_Options =
    | "_createdAt"
    | "_updatedAt"
    | "auth"
    | "endpoint"
    | "expirationTime"
    | "id"
    | "p256dh"
    | "refreshCount";

export type UsageInfo = {
    __typename?: "UsageInfo";
    deviceFormFactor?: Maybe<Scalars["String"]["output"]>;
    deviceName?: Maybe<Scalars["String"]["output"]>;
    ipCountry?: Maybe<Scalars["String"]["output"]>;
    lastUsed?: Maybe<Scalars["String"]["output"]>;
};

export type User = Node & {
    __typename?: "User";
    _meta?: Maybe<SystemMetaData>;
    _partition: User;
    /** @deprecated Do not use this field */
    acceptedTerms?: Maybe<Scalars["String"]["output"]>;
    accounts?: Maybe<Array<UserAccount>>;
    accounts2?: Maybe<Array<UserAccount2>>;
    clearSessions?: Maybe<Scalars["String"]["output"]>;
    /** @deprecated Do not use this field */
    email?: Maybe<Scalars["String"]["output"]>;
    firstName?: Maybe<Scalars["String"]["output"]>;
    id: Scalars["ID"]["output"];
    ipCountryStats?: Maybe<Scalars["JSONObject"]["output"]>;
    lastName?: Maybe<Scalars["String"]["output"]>;
    linkedIdentities?: Maybe<Array<UserLookup>>;
    loginSessions?: Maybe<Array<RefreshToken>>;
    lookups?: Maybe<Array<UserLookup>>;
    /** @deprecated Do not use this field */
    password?: Maybe<Scalars["String"]["output"]>;
    pendingEmails?: Maybe<Array<PendingEmailVerification>>;
    primaryEmail?: Maybe<Scalars["String"]["output"]>;
    profilePicture?: Maybe<Scalars["String"]["output"]>;
    /** @deprecated Do not use this field */
    status?: Maybe<Scalars["String"]["output"]>;
    trustedDevices?: Maybe<Array<TrustedDevice>>;
};

export type UserAccountsArgs = {
    after?: InputMaybe<Scalars["String"]["input"]>;
    filter?: InputMaybe<UserAccount_Filter>;
    first?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<UserAccount_Sort>;
};

export type UserAccounts2Args = {
    after?: InputMaybe<Scalars["String"]["input"]>;
    filter?: InputMaybe<UserAccount2_Filter>;
    first?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<UserAccount2_Sort>;
};

export type UserLinkedIdentitiesArgs = {
    after?: InputMaybe<Scalars["String"]["input"]>;
    filter?: InputMaybe<UserLookup_Filter>;
    first?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<UserLookup_Sort>;
};

export type UserLoginSessionsArgs = {
    after?: InputMaybe<Scalars["String"]["input"]>;
    filter?: InputMaybe<RefreshToken_Filter>;
    first?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<RefreshToken_Sort>;
};

export type UserLookupsArgs = {
    after?: InputMaybe<Scalars["String"]["input"]>;
    filter?: InputMaybe<UserLookup_Filter>;
    first?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<UserLookup_Sort>;
};

export type UserPendingEmailsArgs = {
    after?: InputMaybe<Scalars["String"]["input"]>;
    filter?: InputMaybe<PendingEmailVerification_Filter>;
    first?: InputMaybe<Scalars["Int"]["input"]>;
    sort?: InputMaybe<PendingEmailVerification_Sort>;
};

export type UserAccount = Node & {
    __typename?: "UserAccount";
    _meta?: Maybe<SystemMetaData>;
    _partition: User;
    account: AccountUser;
    id: Scalars["ID"]["output"];
    user: User;
};

export type UserAccount2 = Node & {
    __typename?: "UserAccount2";
    _meta?: Maybe<SystemMetaData>;
    _partition: User;
    account: Account;
    id: Scalars["ID"]["output"];
    owner: Scalars["Boolean"]["output"];
    scopes?: Maybe<Array<Scalars["String"]["output"]>>;
};

export type UserAccount2_Filter = {
    _meta?: InputMaybe<SystemMetaData_Filter>;
    _search?: InputMaybe<Scalars["String"]["input"]>;
    account?: InputMaybe<NodeReference_Filter>;
    id?: InputMaybe<Id_Filter>;
    owner?: InputMaybe<Boolean_Filter>;
};

export type UserAccount2_Sort = {
    by?: InputMaybe<Array<InputMaybe<UserAccount2_Sort_Options>>>;
    order?: InputMaybe<Sort>;
};

export type UserAccount2_Sort_Options = "_createdAt" | "_updatedAt" | "id" | "scopes";

export type UserAccount_Filter = {
    _meta?: InputMaybe<SystemMetaData_Filter>;
    _search?: InputMaybe<Scalars["String"]["input"]>;
    account?: InputMaybe<NodeReference_Filter>;
    id?: InputMaybe<Id_Filter>;
    user?: InputMaybe<NodeReference_Filter>;
};

export type UserAccount_Sort = {
    by?: InputMaybe<Array<InputMaybe<UserAccount_Sort_Options>>>;
    order?: InputMaybe<Sort>;
};

export type UserAccount_Sort_Options = "_createdAt" | "_updatedAt" | "id";

export type UserLookup = Node & {
    __typename?: "UserLookup";
    _meta?: Maybe<SystemMetaData>;
    _partition: UserLookup;
    authenticator?: Maybe<Authenticator>;
    displayAvatar?: Maybe<Scalars["String"]["output"]>;
    displayName?: Maybe<Scalars["String"]["output"]>;
    /** @deprecated Use 'info' instead */
    email?: Maybe<Scalars["String"]["output"]>;
    externalId: Scalars["ID"]["output"];
    /** @deprecated Use 'info' instead */
    firstName?: Maybe<Scalars["String"]["output"]>;
    id: Scalars["ID"]["output"];
    info?: Maybe<UserLookupInfo>;
    /** @deprecated Use 'info' instead */
    lastName?: Maybe<Scalars["String"]["output"]>;
    /** @deprecated Use 'info' instead */
    lastUsed?: Maybe<Scalars["String"]["output"]>;
    /** @deprecated Use 'info' instead */
    profilePicture?: Maybe<Scalars["String"]["output"]>;
    provider: Scalars["ID"]["output"];
    /** @deprecated Use 'info' instead */
    refreshToken?: Maybe<Scalars["String"]["output"]>;
    /** @deprecated Use 'info' instead */
    refreshTokenExpires?: Maybe<Scalars["String"]["output"]>;
    /** @deprecated Use 'info' instead */
    scope?: Maybe<Scalars["String"]["output"]>;
    usageInfo?: Maybe<UsageInfo>;
    usageTracking?: Maybe<Scalars["JSONObject"]["output"]>;
    user: User;
};

export type UserLookupEmail = {
    __typename?: "UserLookupEmail";
    pinEnabled?: Maybe<Scalars["Boolean"]["output"]>;
};

export type UserLookupInfo = UserLookupEmail | UserLookupOauth | UserLookupPasskey;

export type UserLookupOauth = {
    __typename?: "UserLookupOauth";
    email: Scalars["String"]["output"];
    firstName?: Maybe<Scalars["String"]["output"]>;
    lastName?: Maybe<Scalars["String"]["output"]>;
    profilePicture?: Maybe<Scalars["String"]["output"]>;
};

export type UserLookupPasskey = {
    __typename?: "UserLookupPasskey";
    algorithm: UserLookupPasskeyAlgo;
    counter: Scalars["Int"]["output"];
    credentialId: Scalars["ID"]["output"];
    publicKey: Scalars["String"]["output"];
};

export type UserLookupPasskeyAlgo = "ES256" | "EdDSA" | "RS256";

export type UserLookup_Filter = {
    _meta?: InputMaybe<SystemMetaData_Filter>;
    _search?: InputMaybe<Scalars["String"]["input"]>;
    authenticator?: InputMaybe<NodeReference_Filter>;
    displayAvatar?: InputMaybe<String_Filter>;
    displayName?: InputMaybe<String_Filter>;
    email?: InputMaybe<String_Filter>;
    externalId?: InputMaybe<Id_Filter>;
    firstName?: InputMaybe<String_Filter>;
    id?: InputMaybe<Id_Filter>;
    lastName?: InputMaybe<String_Filter>;
    lastUsed?: InputMaybe<DateTime_Filter>;
    profilePicture?: InputMaybe<String_Filter>;
    provider?: InputMaybe<Id_Filter>;
    refreshToken?: InputMaybe<String_Filter>;
    refreshTokenExpires?: InputMaybe<String_Filter>;
    scope?: InputMaybe<String_Filter>;
    usageTracking?: InputMaybe<JsonObject_Filter>;
    user?: InputMaybe<NodeReference_Filter>;
};

export type UserLookup_Sort = {
    by?: InputMaybe<Array<InputMaybe<UserLookup_Sort_Options>>>;
    order?: InputMaybe<Sort>;
};

export type UserLookup_Sort_Options =
    | "_createdAt"
    | "_updatedAt"
    | "displayAvatar"
    | "displayName"
    | "email"
    | "externalId"
    | "firstName"
    | "id"
    | "lastName"
    | "profilePicture"
    | "provider"
    | "refreshToken"
    | "refreshTokenExpires"
    | "scope";

export type User_Filter = {
    _meta?: InputMaybe<SystemMetaData_Filter>;
    _search?: InputMaybe<Scalars["String"]["input"]>;
    acceptedTerms?: InputMaybe<String_Filter>;
    clearSessions?: InputMaybe<DateTime_Filter>;
    email?: InputMaybe<String_Filter>;
    firstName?: InputMaybe<String_Filter>;
    id?: InputMaybe<Id_Filter>;
    ipCountryStats?: InputMaybe<JsonObject_Filter>;
    lastName?: InputMaybe<String_Filter>;
    password?: InputMaybe<String_Filter>;
    primaryEmail?: InputMaybe<String_Filter>;
    profilePicture?: InputMaybe<String_Filter>;
    status?: InputMaybe<String_Filter>;
};

export type User_Lookup = {
    id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type User_Sort = {
    by?: InputMaybe<Array<InputMaybe<User_Sort_Options>>>;
    order?: InputMaybe<Sort>;
};

export type User_Sort_Options =
    | "_createdAt"
    | "_updatedAt"
    | "acceptedTerms"
    | "email"
    | "firstName"
    | "id"
    | "lastName"
    | "password"
    | "primaryEmail"
    | "profilePicture"
    | "status";

export type _Collection = Node & {
    __typename?: "_Collection";
    _meta?: Maybe<SystemMetaData>;
    description?: Maybe<Scalars["String"]["output"]>;
    id: Scalars["ID"]["output"];
    partitioned?: Maybe<Scalars["Boolean"]["output"]>;
    partitionedBy?: Maybe<_SystemType>;
};

export type _Collection_Filter = {
    _meta?: InputMaybe<SystemMetaData_Filter>;
    _search?: InputMaybe<Scalars["String"]["input"]>;
    description?: InputMaybe<String_Filter>;
    id?: InputMaybe<Id_Filter>;
    partitioned?: InputMaybe<Boolean_Filter>;
    partitionedBy?: InputMaybe<NodeReference_Filter>;
};

export type _Collection_Sort = {
    by?: InputMaybe<Array<InputMaybe<_Collection_Sort_Options>>>;
    order?: InputMaybe<Sort>;
};

export type _Collection_Sort_Options = "_createdAt" | "_updatedAt" | "description" | "id";

export type _CreateOptions = {
    onExists?: InputMaybe<_CreateOptions_OnExists>;
};

export type _CreateOptions_OnExists = "ERROR" | "RETURN" | "UPDATE";

export type _DeleteOptions = {
    eTag?: InputMaybe<Scalars["String"]["input"]>;
};

/** Field is part of id */
export type _IdType =
    /** Id needs to be provided by the user, system will not generate the default ID */
    | "EXTERNAL"
    /** User cannot provide ID during creation */
    | "SYSTEM";

export type _SystemDirective = {
    __typename?: "_SystemDirective";
    directive: Scalars["String"]["output"];
    spec?: Maybe<_SystemDirectiveSpec>;
};

export type _SystemDirectiveAction = {
    __typename?: "_SystemDirectiveAction";
    async?: Maybe<Scalars["Boolean"]["output"]>;
    lock?: Maybe<Scalars["Boolean"]["output"]>;
    type: Scalars["String"]["output"];
};

export type _SystemDirectiveAlias = {
    __typename?: "_SystemDirectiveAlias";
    for?: Maybe<Scalars["String"]["output"]>;
};

export type _SystemDirectiveAspect = {
    __typename?: "_SystemDirectiveAspect";
    aspect: Scalars["String"]["output"];
    default?: Maybe<Scalars["String"]["output"]>;
    type: Scalars["String"]["output"];
};

export type _SystemDirectiveAuth = {
    __typename?: "_SystemDirectiveAuth";
    operation?: Maybe<AuthOperations>;
    permission?: Maybe<Scalars["String"]["output"]>;
    roles?: Maybe<Array<Scalars["String"]["output"]>>;
    tag?: Maybe<Scalars["String"]["output"]>;
    value?: Maybe<Scalars["String"]["output"]>;
};

export type _SystemDirectiveDeprecated = {
    __typename?: "_SystemDirectiveDeprecated";
    reason?: Maybe<Scalars["String"]["output"]>;
};

export type _SystemDirectiveEntity = {
    __typename?: "_SystemDirectiveEntity";
    collection?: Maybe<Scalars["String"]["output"]>;
    external?: Maybe<Scalars["String"]["output"]>;
    singleton?: Maybe<Scalars["Boolean"]["output"]>;
    version?: Maybe<Scalars["String"]["output"]>;
};

export type _SystemDirectiveFeature = {
    __typename?: "_SystemDirectiveFeature";
    neededFor?: Maybe<Array<Scalars["String"]["output"]>>;
};

export type _SystemDirectiveId = {
    __typename?: "_SystemDirectiveId";
    idType?: Maybe<_IdType>;
};

export type _SystemDirectiveLookup = {
    __typename?: "_SystemDirectiveLookup";
    key?: Maybe<Scalars["String"]["output"]>;
};

export type _SystemDirectiveMaterialized = {
    __typename?: "_SystemDirectiveMaterialized";
    fragment: Scalars["String"]["output"];
};

export type _SystemDirectiveSpec =
    | _SystemDirectiveAction
    | _SystemDirectiveAlias
    | _SystemDirectiveAspect
    | _SystemDirectiveAuth
    | _SystemDirectiveDeprecated
    | _SystemDirectiveEntity
    | _SystemDirectiveFeature
    | _SystemDirectiveId
    | _SystemDirectiveLookup
    | _SystemDirectiveMaterialized
    | _SystemDirectiveSubentity
    | _SystemDirectiveTag
    | _SystemDirectiveVisibility;

export type _SystemDirectiveSubentity = {
    __typename?: "_SystemDirectiveSubentity";
    index?: Maybe<Scalars["String"]["output"]>;
    resolver?: Maybe<Scalars["String"]["output"]>;
};

export type _SystemDirectiveTag = {
    __typename?: "_SystemDirectiveTag";
    tags?: Maybe<Array<Scalars["String"]["output"]>>;
};

export type _SystemDirectiveVisibility = {
    __typename?: "_SystemDirectiveVisibility";
    create?: Maybe<Scalars["Boolean"]["output"]>;
    delete?: Maybe<Scalars["Boolean"]["output"]>;
    fetch?: Maybe<Scalars["Boolean"]["output"]>;
    gateway?: Maybe<Scalars["String"]["output"]>;
    link?: Maybe<Scalars["Boolean"]["output"]>;
    list?: Maybe<Scalars["Boolean"]["output"]>;
    other?: Maybe<Scalars["Boolean"]["output"]>;
    pages?: Maybe<Scalars["Boolean"]["output"]>;
    update?: Maybe<Scalars["Boolean"]["output"]>;
};

export type _SystemType = Node & {
    __typename?: "_SystemType";
    _meta?: Maybe<SystemMetaData>;
    collection?: Maybe<_Collection>;
    id: Scalars["ID"]["output"];
    sdl?: Maybe<Scalars["String"]["output"]>;
    types?: Maybe<Array<_SystemTypeBase>>;
    version?: Maybe<Scalars["String"]["output"]>;
};

export type _SystemTypeAction = _SystemTypeBase &
    _SystemTypeComplex & {
        __typename?: "_SystemTypeAction";
        description?: Maybe<Scalars["String"]["output"]>;
        directives?: Maybe<Array<_SystemDirective>>;
        fields?: Maybe<Array<_SystemTypeComplexField>>;
        id: Scalars["ID"]["output"];
    };

export type _SystemTypeBase = {
    description?: Maybe<Scalars["String"]["output"]>;
    directives?: Maybe<Array<_SystemDirective>>;
    id: Scalars["ID"]["output"];
};

export type _SystemTypeComplex = {
    description?: Maybe<Scalars["String"]["output"]>;
    directives?: Maybe<Array<_SystemDirective>>;
    fields?: Maybe<Array<_SystemTypeComplexField>>;
    id: Scalars["ID"]["output"];
};

export type _SystemTypeComplexField = {
    __typename?: "_SystemTypeComplexField";
    array: Scalars["Boolean"]["output"];
    description?: Maybe<Scalars["String"]["output"]>;
    directives?: Maybe<Array<_SystemDirective>>;
    name: Scalars["String"]["output"];
    required: Scalars["Boolean"]["output"];
    type: Scalars["String"]["output"];
};

export type _SystemTypeEnum = _SystemTypeBase & {
    __typename?: "_SystemTypeEnum";
    description?: Maybe<Scalars["String"]["output"]>;
    directives?: Maybe<Array<_SystemDirective>>;
    id: Scalars["ID"]["output"];
    values?: Maybe<Array<_SystemTypeEnumValue>>;
};

export type _SystemTypeEnumValue = {
    __typename?: "_SystemTypeEnumValue";
    description?: Maybe<Scalars["String"]["output"]>;
    directives?: Maybe<Array<_SystemDirective>>;
    name: Scalars["String"]["output"];
};

export type _SystemTypeExtension = _SystemTypeBase &
    _SystemTypeComplex & {
        __typename?: "_SystemTypeExtension";
        description?: Maybe<Scalars["String"]["output"]>;
        directives?: Maybe<Array<_SystemDirective>>;
        fields?: Maybe<Array<_SystemTypeComplexField>>;
        id: Scalars["ID"]["output"];
    };

export type _SystemTypeInterface = _SystemTypeBase &
    _SystemTypeComplex & {
        __typename?: "_SystemTypeInterface";
        description?: Maybe<Scalars["String"]["output"]>;
        directives?: Maybe<Array<_SystemDirective>>;
        fields?: Maybe<Array<_SystemTypeComplexField>>;
        id: Scalars["ID"]["output"];
        implements?: Maybe<Array<Scalars["String"]["output"]>>;
    };

export type _SystemTypeObject = _SystemTypeBase &
    _SystemTypeComplex & {
        __typename?: "_SystemTypeObject";
        description?: Maybe<Scalars["String"]["output"]>;
        directives?: Maybe<Array<_SystemDirective>>;
        fields?: Maybe<Array<_SystemTypeComplexField>>;
        id: Scalars["ID"]["output"];
        implements?: Maybe<Array<Scalars["String"]["output"]>>;
    };

export type _SystemTypeScalar = _SystemTypeBase & {
    __typename?: "_SystemTypeScalar";
    description?: Maybe<Scalars["String"]["output"]>;
    directives?: Maybe<Array<_SystemDirective>>;
    id: Scalars["ID"]["output"];
    package?: Maybe<Scalars["String"]["output"]>;
};

export type _SystemTypeUnion = _SystemTypeBase & {
    __typename?: "_SystemTypeUnion";
    description?: Maybe<Scalars["String"]["output"]>;
    directives?: Maybe<Array<_SystemDirective>>;
    id: Scalars["ID"]["output"];
    oneOf?: Maybe<Array<Scalars["String"]["output"]>>;
};

export type _SystemType_Filter = {
    _meta?: InputMaybe<SystemMetaData_Filter>;
    _search?: InputMaybe<Scalars["String"]["input"]>;
    collection?: InputMaybe<NodeReference_Filter>;
    id?: InputMaybe<Id_Filter>;
};

export type _SystemType_Sort = {
    by?: InputMaybe<Array<InputMaybe<_SystemType_Sort_Options>>>;
    order?: InputMaybe<Sort>;
};

export type _SystemType_Sort_Options = "_createdAt" | "_updatedAt" | "id";

export type _UpdateOptions = {
    eTag?: InputMaybe<Scalars["String"]["input"]>;
};

export type Test_SayHelloQueryVariables = Exact<{ [key: string]: never }>;

export type Test_SayHelloQuery = {
    __typename: "Query";
    fetchAccount?: {
        __typename: "Account";
        id: string;
        name: string;
        description?: string | null;
        deleting?: boolean | null;
        lookup?: { __typename: "AccountLookup2"; id: string } | null;
    } | null;
};

export type TestAccountQueryVariables = Exact<{
    slug: Scalars["String"]["input"];
}>;

export type TestAccountQuery = { __typename: "Query"; testAccount: boolean };

export type RegisterAccountMutationVariables = Exact<{
    slug: Scalars["String"]["input"];
    name: Scalars["String"]["input"];
}>;

export type RegisterAccountMutation = {
    __typename: "Mutation";
    registerAccount: { __typename: "AccountStub"; id: string; name: string };
};

export type CreateEnvironmentMutationVariables = Exact<{
    lookup: Scalars["String"]["input"];
    name: Scalars["String"]["input"];
    description?: InputMaybe<Scalars["String"]["input"]>;
    deleteProtection?: InputMaybe<Scalars["Boolean"]["input"]>;
    upstream?: InputMaybe<NodeReference>;
    color?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type CreateEnvironmentMutation = {
    __typename: "Mutation";
    createEnvironment: { __typename: "Environment2"; id: string };
};

export type EnablePushNotificationsMutationVariables = Exact<{
    endpoint: Scalars["String"]["input"];
    expirationTime?: InputMaybe<Scalars["String"]["input"]>;
    auth: Scalars["String"]["input"];
    p256dh: Scalars["String"]["input"];
}>;

export type EnablePushNotificationsMutation = {
    __typename: "Mutation";
    enablePushNotifications: { __typename: "TrustedDevice"; id: string; pushEnabled?: boolean | null };
};

export type DisablePushNotificationsMutationVariables = Exact<{
    deviceId?: InputMaybe<Scalars["ID"]["input"]>;
}>;

export type DisablePushNotificationsMutation = {
    __typename: "Mutation";
    disablePushNotifications: { __typename: "TrustedDevice"; id: string; pushEnabled?: boolean | null };
};

export type UseLoginStateProvidersQueryVariables = Exact<{ [key: string]: never }>;

export type UseLoginStateProvidersQuery = {
    __typename: "Query";
    iamSettings?: {
        __typename: "IamSettings";
        id: string;
        pinEnabled: boolean;
        oauthEnabled: boolean;
        passkeyEnabled: boolean;
        trustedDeviceEnabled: boolean;
        message?: string | null;
    } | null;
    listOAuthProviders?: Array<{
        __typename: "OAuthProvider2";
        id: string;
        enabled: Array<Deployments>;
        clientId: string;
        authorizationEndpoint: string;
        params: Array<{ __typename: "OAuthProvider2Params"; key: string; value: string }>;
        authenticator: {
            __typename: "Authenticator";
            id: string;
            name: string;
            loginWithLabel?: string | null;
            darkMode?: {
                __typename: "AuthenticatorTheme";
                fontFamily?: string | null;
                fontWeight?: string | null;
                textColor?: string | null;
                backgroundColor?: string | null;
                backgroundHoverColor?: string | null;
                iconData?: string | null;
            } | null;
            lightMode?: {
                __typename: "AuthenticatorTheme";
                fontFamily?: string | null;
                fontWeight?: string | null;
                textColor?: string | null;
                backgroundColor?: string | null;
                backgroundHoverColor?: string | null;
                iconData?: string | null;
            } | null;
        };
    }> | null;
};

export type LoginWithPartnerCodeMutationVariables = Exact<{
    provider: Scalars["String"]["input"];
    code: Scalars["String"]["input"];
    referrer: Scalars["String"]["input"];
    rememberMe?: InputMaybe<Scalars["Boolean"]["input"]>;
    d4d27456?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type LoginWithPartnerCodeMutation = {
    __typename: "Mutation";
    session: {
        __typename: "AuthSession";
        id: string;
        type: AuthSessionType;
        state: AuthSessionState;
        challenge: string;
        expiresAt: string;
        _eid?: string | null;
    };
};

export type FetchAuthSessionQueryVariables = Exact<{
    id: Scalars["ID"]["input"];
    d4d27456?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type FetchAuthSessionQuery = {
    __typename: "Query";
    session: {
        __typename: "AuthSession";
        id: string;
        type: AuthSessionType;
        state: AuthSessionState;
        challenge: string;
        expiresAt: string;
        _eid?: string | null;
    };
};

export type StartAuthSessionMutationVariables = Exact<{
    email?: InputMaybe<Scalars["String"]["input"]>;
    rememberMe?: InputMaybe<Scalars["Boolean"]["input"]>;
}>;

export type StartAuthSessionMutation = {
    __typename: "Mutation";
    session: { __typename: "AuthSession"; id: string; type: AuthSessionType; challenge: string; expiresAt: string };
};

export type RegisterPasskeyMutationVariables = Exact<{
    sessionId: Scalars["ID"]["input"];
    referrer: Scalars["String"]["input"];
    registration: Scalars["JSONObject"]["input"];
}>;

export type RegisterPasskeyMutation = { __typename: "Mutation"; registerPasskey: boolean };

export type LoginWithPasskeyMutationVariables = Exact<{
    sessionId: Scalars["ID"]["input"];
    referrer: Scalars["String"]["input"];
    rememberMe: Scalars["Boolean"]["input"];
    authentication: Scalars["JSONObject"]["input"];
}>;

export type LoginWithPasskeyMutation = { __typename: "Mutation"; loginWithPasskey: boolean };

export type ActivateAuthSessionMutationVariables = Exact<{
    id: Scalars["ID"]["input"];
    state: AuthSessionState;
}>;

export type ActivateAuthSessionMutation = {
    __typename: "Mutation";
    activateAuthSession: {
        __typename: "ActivateAuthSessionResponse";
        pin?: string | null;
        session: {
            __typename: "AuthSession";
            id: string;
            type: AuthSessionType;
            challenge: string;
            expiresAt: string;
            state: AuthSessionState;
        };
    };
};

export type ConfirmAuthSessionMutationVariables = Exact<{
    id: Scalars["ID"]["input"];
    qrcode: Scalars["String"]["input"];
}>;

export type ConfirmAuthSessionMutation = {
    __typename: "Mutation";
    confirmAuthSession: {
        __typename: "AuthSession";
        id: string;
        type: AuthSessionType;
        challenge: string;
        expiresAt: string;
        state: AuthSessionState;
    };
};

export type FetchAuthSessionWithCodeQueryVariables = Exact<{
    id: Scalars["ID"]["input"];
}>;

export type FetchAuthSessionWithCodeQuery = {
    __typename: "Query";
    session: {
        __typename: "AuthSession";
        id: string;
        type: AuthSessionType;
        challenge: string;
        expiresAt: string;
        state: AuthSessionState;
    };
};

export type LoginWithEmailCodeMutationVariables = Exact<{
    id: Scalars["ID"]["input"];
    code: Scalars["String"]["input"];
}>;

export type LoginWithEmailCodeMutation = { __typename: "Mutation"; session: boolean };

export type OauthButtonPropsFragment = {
    __typename: "Authenticator";
    name: string;
    loginWithLabel?: string | null;
    darkMode?: {
        __typename: "AuthenticatorTheme";
        fontFamily?: string | null;
        fontWeight?: string | null;
        textColor?: string | null;
        backgroundColor?: string | null;
        backgroundHoverColor?: string | null;
        iconData?: string | null;
    } | null;
    lightMode?: {
        __typename: "AuthenticatorTheme";
        fontFamily?: string | null;
        fontWeight?: string | null;
        textColor?: string | null;
        backgroundColor?: string | null;
        backgroundHoverColor?: string | null;
        iconData?: string | null;
    } | null;
} & { " $fragmentName"?: "OauthButtonPropsFragment" };

export type CreateProjectMutationVariables = Exact<{
    lookup: Scalars["String"]["input"];
    name: Scalars["String"]["input"];
    type: Project2Type;
}>;

export type CreateProjectMutation = { __typename: "Mutation"; createProject: { __typename: "Project2"; id: string } };

export type UseDataProjectSchemaQueryVariables = Exact<{ [key: string]: never }>;

export type UseDataProjectSchemaQuery = {
    __typename: "Query";
    listSystemTypes?: Array<{
        __typename: "_SystemType";
        id: string;
        types?: Array<
            | {
                  __typename: "_SystemTypeAction";
                  id: string;
                  fields?: Array<{ __typename: "_SystemTypeComplexField"; name: string; type: string }> | null;
              }
            | { __typename: "_SystemTypeEnum"; id: string }
            | {
                  __typename: "_SystemTypeExtension";
                  id: string;
                  fields?: Array<{ __typename: "_SystemTypeComplexField"; name: string; type: string }> | null;
              }
            | {
                  __typename: "_SystemTypeInterface";
                  id: string;
                  fields?: Array<{ __typename: "_SystemTypeComplexField"; name: string; type: string }> | null;
              }
            | {
                  __typename: "_SystemTypeObject";
                  id: string;
                  fields?: Array<{ __typename: "_SystemTypeComplexField"; name: string; type: string }> | null;
              }
            | { __typename: "_SystemTypeScalar"; id: string }
            | { __typename: "_SystemTypeUnion"; id: string }
        > | null;
        collection?: { __typename: "_Collection"; id: string } | null;
    }> | null;
};

export type UnlinkIdentityProviderMutationVariables = Exact<{
    id: Scalars["ID"]["input"];
}>;

export type UnlinkIdentityProviderMutation = { __typename: "Mutation"; unlinkIdentityProvider: boolean };

export type CreateEmailVerificationCodeMutationVariables = Exact<{
    email: Scalars["String"]["input"];
}>;

export type CreateEmailVerificationCodeMutation = { __typename: "Mutation"; createEmailVerificationCode: boolean };

export type ConfirmEmailVerificationCodeMutationVariables = Exact<{
    email: Scalars["String"]["input"];
    code: Scalars["String"]["input"];
}>;

export type ConfirmEmailVerificationCodeMutation = { __typename: "Mutation"; verifyEmail: boolean };

export type DeletePendingEmailVerificationMutationVariables = Exact<{
    id: Scalars["ID"]["input"];
}>;

export type DeletePendingEmailVerificationMutation = {
    __typename: "Mutation";
    deletePendingEmailVerification: { __typename: "PendingEmailVerification"; id: string };
};

export type IdentityProviderEmailFragment = {
    __typename: "UserLookup";
    id: string;
    provider: string;
    displayName?: string | null;
    displayAvatar?: string | null;
    usageInfo?: { __typename: "UsageInfo"; lastUsed?: string | null } | null;
    info?:
        | { __typename: "UserLookupEmail"; pinEnabled?: boolean | null }
        | { __typename: "UserLookupOauth" }
        | { __typename: "UserLookupPasskey" }
        | null;
} & { " $fragmentName"?: "IdentityProviderEmailFragment" };

export type PendingEmailVerificationFragment = {
    __typename: "PendingEmailVerification";
    id: string;
    email: string;
    _meta?: { __typename: "SystemMetaData"; createdAt?: string | null } | null;
} & { " $fragmentName"?: "PendingEmailVerificationFragment" };

export type UpdateAccountEmailSettingsMutationVariables = Exact<{
    email: Scalars["String"]["input"];
    pinEnabled: Scalars["Boolean"]["input"];
}>;

export type UpdateAccountEmailSettingsMutation = {
    __typename: "Mutation";
    updateAccountEmailSettings: {
        __typename: "UserLookup";
        id: string;
        info?:
            | { __typename: "UserLookupEmail"; pinEnabled?: boolean | null }
            | { __typename: "UserLookupOauth" }
            | { __typename: "UserLookupPasskey" }
            | null;
    };
};

export type TrustedDeviceListFragment = {
    __typename: "TrustedDevice";
    id: string;
    currentSession?: boolean | null;
    pushEnabled?: boolean | null;
    usageInfo?: {
        __typename: "UsageInfo";
        deviceName?: string | null;
        deviceFormFactor?: string | null;
        ipCountry?: string | null;
        lastUsed?: string | null;
    } | null;
} & { " $fragmentName"?: "TrustedDeviceListFragment" };

export type RegisterPartnerCodeMutationVariables = Exact<{
    provider: Scalars["String"]["input"];
    code: Scalars["String"]["input"];
    referrer: Scalars["String"]["input"];
}>;

export type RegisterPartnerCodeMutation = { __typename: "Mutation"; registerPartnerCode: boolean };

export type IdentityProvider2Fragment = {
    __typename: "UserLookup";
    id: string;
    provider: string;
    displayName?: string | null;
    displayAvatar?: string | null;
    usageInfo?: {
        __typename: "UsageInfo";
        deviceName?: string | null;
        deviceFormFactor?: string | null;
        ipCountry?: string | null;
        lastUsed?: string | null;
    } | null;
    authenticator?: {
        __typename: "Authenticator";
        id: string;
        name: string;
        darkMode?: { __typename: "AuthenticatorTheme"; iconData?: string | null } | null;
        lightMode?: { __typename: "AuthenticatorTheme"; iconData?: string | null } | null;
    } | null;
} & { " $fragmentName"?: "IdentityProvider2Fragment" };

export type IdentityProviderFragment = {
    __typename: "UserLookup";
    id: string;
    provider: string;
    displayName?: string | null;
    displayAvatar?: string | null;
    usageInfo?: {
        __typename: "UsageInfo";
        deviceName?: string | null;
        deviceFormFactor?: string | null;
        ipCountry?: string | null;
        lastUsed?: string | null;
    } | null;
    authenticator?: {
        __typename: "Authenticator";
        id: string;
        name: string;
        loginWithLabel?: string | null;
        darkMode?: {
            __typename: "AuthenticatorTheme";
            fontFamily?: string | null;
            fontWeight?: string | null;
            textColor?: string | null;
            backgroundColor?: string | null;
            iconData?: string | null;
        } | null;
        lightMode?: {
            __typename: "AuthenticatorTheme";
            fontFamily?: string | null;
            fontWeight?: string | null;
            textColor?: string | null;
            backgroundColor?: string | null;
            iconData?: string | null;
        } | null;
    } | null;
} & { " $fragmentName"?: "IdentityProviderFragment" };

export type DeleteTrustedDeviceMutationVariables = Exact<{
    id: Scalars["ID"]["input"];
}>;

export type DeleteTrustedDeviceMutation = {
    __typename: "Mutation";
    deleteTrustedDevice: { __typename: "TrustedDevice"; id: string };
};

export type TestPushNotificationsMutationVariables = Exact<{
    deviceId?: InputMaybe<Scalars["ID"]["input"]>;
}>;

export type TestPushNotificationsMutation = { __typename: "Mutation"; testPushNotifications: boolean };

export type UseDataAccountEnvironmentsQueryVariables = Exact<{ [key: string]: never }>;

export type UseDataAccountEnvironmentsQuery = {
    __typename: "Query";
    environments?: Array<{
        __typename: "Environment2";
        id: string;
        name: string;
        lookup: string;
        description?: string | null;
    }> | null;
};

export type UseDataAccountProjectsQueryVariables = Exact<{ [key: string]: never }>;

export type UseDataAccountProjectsQuery = {
    __typename: "Query";
    projects?: Array<{ __typename: "Project2"; id: string; name: string; lookup: string; type: Project2Type }> | null;
};

export type UseDataUserAccountsQueryVariables = Exact<{ [key: string]: never }>;

export type UseDataUserAccountsQuery = {
    __typename: "Query";
    listUserAccounts: Array<{
        __typename: "AccountStub";
        id: string;
        name: string;
        lookup: string;
        projects?: Array<{
            __typename: "ProjectStub";
            id: string;
            name: string;
            lookup: string;
            type: ProjectStubType;
        }> | null;
    }>;
};

export type LogoutMutationVariables = Exact<{ [key: string]: never }>;

export type LogoutMutation = { __typename: "Mutation"; logout: boolean };

export type UseMe_QueryQueryVariables = Exact<{ [key: string]: never }>;

export type UseMe_QueryQuery = {
    __typename: "Query";
    user?: {
        __typename: "User";
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        primaryEmail?: string | null;
        profilePicture?: string | null;
    } | null;
};

export type RefreshLoginMutationVariables = Exact<{ [key: string]: never }>;

export type RefreshLoginMutation = { __typename: "Mutation"; refreshToken: boolean };

export type SettingsMePage_InfoQueryQueryVariables = Exact<{ [key: string]: never }>;

export type SettingsMePage_InfoQueryQuery = {
    __typename: "Query";
    user?: {
        __typename: "User";
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        primaryEmail?: string | null;
        profilePicture?: string | null;
        linkedIdentities?: Array<
            { __typename: "UserLookup"; id: string; provider: string } & {
                " $fragmentRefs"?: {
                    IdentityProviderFragment: IdentityProviderFragment;
                    IdentityProvider2Fragment: IdentityProvider2Fragment;
                    IdentityProviderEmailFragment: IdentityProviderEmailFragment;
                };
            }
        > | null;
    } | null;
    tokens?: Array<
        { __typename: "TrustedDevice" } & {
            " $fragmentRefs"?: { TrustedDeviceListFragment: TrustedDeviceListFragment };
        }
    > | null;
    pending?: Array<
        { __typename: "PendingEmailVerification" } & {
            " $fragmentRefs"?: { PendingEmailVerificationFragment: PendingEmailVerificationFragment };
        }
    > | null;
};

export type UpdateUserProfileMutationVariables = Exact<{
    firstName: Scalars["String"]["input"];
    lastName: Scalars["String"]["input"];
    primaryEmail: Scalars["String"]["input"];
    profilePicture?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type UpdateUserProfileMutation = {
    __typename: "Mutation";
    updateUserProfile: {
        __typename: "User";
        id: string;
        firstName?: string | null;
        lastName?: string | null;
        primaryEmail?: string | null;
        profilePicture?: string | null;
    };
};

export type AccountSettingsPage_DeleteMutationVariables = Exact<{
    slug: Scalars["String"]["input"];
}>;

export type AccountSettingsPage_DeleteMutation = {
    __typename: "Mutation";
    deleteAccount: { __typename: "Account"; id: string; deleting?: boolean | null };
};

export type ListCollectionsQueryVariables = Exact<{ [key: string]: never }>;

export type ListCollectionsQuery = {
    __typename: "Query";
    listCollections?: Array<{
        __typename: "_Collection";
        id: string;
        description?: string | null;
        partitioned?: boolean | null;
        partitionedBy?: { __typename: "_SystemType"; id: string } | null;
    }> | null;
};

export type AccountHome_QueryQueryVariables = Exact<{ [key: string]: never }>;

export type AccountHome_QueryQuery = {
    __typename: "Query";
    account?: { __typename: "Account"; id: string; name: string } | null;
};

export const OauthButtonPropsFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "OauthButtonProps" },
            typeCondition: { kind: "NamedType", name: { kind: "Name", value: "Authenticator" } },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "__typename" } },
                    { kind: "Field", name: { kind: "Name", value: "name" } },
                    { kind: "Field", name: { kind: "Name", value: "loginWithLabel" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "darkMode" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                { kind: "Field", name: { kind: "Name", value: "fontFamily" } },
                                { kind: "Field", name: { kind: "Name", value: "fontWeight" } },
                                { kind: "Field", name: { kind: "Name", value: "textColor" } },
                                { kind: "Field", name: { kind: "Name", value: "backgroundColor" } },
                                { kind: "Field", name: { kind: "Name", value: "backgroundHoverColor" } },
                                { kind: "Field", name: { kind: "Name", value: "iconData" } },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "lightMode" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                { kind: "Field", name: { kind: "Name", value: "fontFamily" } },
                                { kind: "Field", name: { kind: "Name", value: "fontWeight" } },
                                { kind: "Field", name: { kind: "Name", value: "textColor" } },
                                { kind: "Field", name: { kind: "Name", value: "backgroundColor" } },
                                { kind: "Field", name: { kind: "Name", value: "backgroundHoverColor" } },
                                { kind: "Field", name: { kind: "Name", value: "iconData" } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<OauthButtonPropsFragment, unknown>;
export const IdentityProviderEmailFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "IdentityProviderEmail" },
            typeCondition: { kind: "NamedType", name: { kind: "Name", value: "UserLookup" } },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "__typename" } },
                    { kind: "Field", name: { kind: "Name", value: "id" } },
                    { kind: "Field", name: { kind: "Name", value: "provider" } },
                    { kind: "Field", name: { kind: "Name", value: "displayName" } },
                    { kind: "Field", name: { kind: "Name", value: "displayAvatar" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "usageInfo" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                { kind: "Field", name: { kind: "Name", value: "lastUsed" } },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "info" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: { kind: "Name", value: "UserLookupEmail" },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                            { kind: "Field", name: { kind: "Name", value: "pinEnabled" } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<IdentityProviderEmailFragment, unknown>;
export const PendingEmailVerificationFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "PendingEmailVerification" },
            typeCondition: { kind: "NamedType", name: { kind: "Name", value: "PendingEmailVerification" } },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "__typename" } },
                    { kind: "Field", name: { kind: "Name", value: "id" } },
                    { kind: "Field", name: { kind: "Name", value: "email" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "_meta" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<PendingEmailVerificationFragment, unknown>;
export const TrustedDeviceListFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "TrustedDeviceList" },
            typeCondition: { kind: "NamedType", name: { kind: "Name", value: "TrustedDevice" } },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "__typename" } },
                    { kind: "Field", name: { kind: "Name", value: "id" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "usageInfo" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                { kind: "Field", name: { kind: "Name", value: "deviceName" } },
                                { kind: "Field", name: { kind: "Name", value: "deviceFormFactor" } },
                                { kind: "Field", name: { kind: "Name", value: "ipCountry" } },
                                { kind: "Field", name: { kind: "Name", value: "lastUsed" } },
                            ],
                        },
                    },
                    { kind: "Field", name: { kind: "Name", value: "currentSession" } },
                    { kind: "Field", name: { kind: "Name", value: "pushEnabled" } },
                ],
            },
        },
    ],
} as unknown as DocumentNode<TrustedDeviceListFragment, unknown>;
export const IdentityProvider2FragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "IdentityProvider2" },
            typeCondition: { kind: "NamedType", name: { kind: "Name", value: "UserLookup" } },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "__typename" } },
                    { kind: "Field", name: { kind: "Name", value: "id" } },
                    { kind: "Field", name: { kind: "Name", value: "provider" } },
                    { kind: "Field", name: { kind: "Name", value: "displayName" } },
                    { kind: "Field", name: { kind: "Name", value: "displayAvatar" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "usageInfo" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                { kind: "Field", name: { kind: "Name", value: "deviceName" } },
                                { kind: "Field", name: { kind: "Name", value: "deviceFormFactor" } },
                                { kind: "Field", name: { kind: "Name", value: "ipCountry" } },
                                { kind: "Field", name: { kind: "Name", value: "lastUsed" } },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "authenticator" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                { kind: "Field", name: { kind: "Name", value: "id" } },
                                { kind: "Field", name: { kind: "Name", value: "name" } },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "darkMode" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                            { kind: "Field", name: { kind: "Name", value: "iconData" } },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lightMode" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                            { kind: "Field", name: { kind: "Name", value: "iconData" } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<IdentityProvider2Fragment, unknown>;
export const IdentityProviderFragmentDoc = {
    kind: "Document",
    definitions: [
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "IdentityProvider" },
            typeCondition: { kind: "NamedType", name: { kind: "Name", value: "UserLookup" } },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "__typename" } },
                    { kind: "Field", name: { kind: "Name", value: "id" } },
                    { kind: "Field", name: { kind: "Name", value: "provider" } },
                    { kind: "Field", name: { kind: "Name", value: "displayName" } },
                    { kind: "Field", name: { kind: "Name", value: "displayAvatar" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "usageInfo" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                { kind: "Field", name: { kind: "Name", value: "deviceName" } },
                                { kind: "Field", name: { kind: "Name", value: "deviceFormFactor" } },
                                { kind: "Field", name: { kind: "Name", value: "ipCountry" } },
                                { kind: "Field", name: { kind: "Name", value: "lastUsed" } },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "authenticator" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                { kind: "Field", name: { kind: "Name", value: "id" } },
                                { kind: "Field", name: { kind: "Name", value: "name" } },
                                { kind: "Field", name: { kind: "Name", value: "loginWithLabel" } },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "darkMode" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                            { kind: "Field", name: { kind: "Name", value: "fontFamily" } },
                                            { kind: "Field", name: { kind: "Name", value: "fontWeight" } },
                                            { kind: "Field", name: { kind: "Name", value: "textColor" } },
                                            { kind: "Field", name: { kind: "Name", value: "backgroundColor" } },
                                            { kind: "Field", name: { kind: "Name", value: "iconData" } },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lightMode" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                            { kind: "Field", name: { kind: "Name", value: "fontFamily" } },
                                            { kind: "Field", name: { kind: "Name", value: "fontWeight" } },
                                            { kind: "Field", name: { kind: "Name", value: "textColor" } },
                                            { kind: "Field", name: { kind: "Name", value: "backgroundColor" } },
                                            { kind: "Field", name: { kind: "Name", value: "iconData" } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<IdentityProviderFragment, unknown>;
export const Test_SayHelloDocument = {
    __meta__: { hash: "2dcf6bb066000702d597e75dc9cb4243a93038e5" },
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "Test_SayHello" },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "__typename" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "fetchAccount" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                { kind: "Field", name: { kind: "Name", value: "id" } },
                                { kind: "Field", name: { kind: "Name", value: "name" } },
                                { kind: "Field", name: { kind: "Name", value: "description" } },
                                { kind: "Field", name: { kind: "Name", value: "deleting" } },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lookup" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                            { kind: "Field", name: { kind: "Name", value: "id" } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<Test_SayHelloQuery, Test_SayHelloQueryVariables>;
export const TestAccountDocument = {
    __meta__: { hash: "1ab9f9e32a829affb81630432a7f46bc6608d7a2" },
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "TestAccount" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "slug" } },
                    type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "__typename" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "testAccount" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "slug" },
                                value: { kind: "Variable", name: { kind: "Name", value: "slug" } },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<TestAccountQuery, TestAccountQueryVariables>;
export const RegisterAccountDocument = {
    __meta__: { hash: "ae388957b521b753eada399098f6d6dc7144e8e5" },
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "RegisterAccount" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "slug" } },
                    type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
                },
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "name" } },
                    type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "__typename" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "registerAccount" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "slug" },
                                value: { kind: "Variable", name: { kind: "Name", value: "slug" } },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "name" },
                                value: { kind: "Variable", name: { kind: "Name", value: "name" } },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                { kind: "Field", name: { kind: "Name", value: "id" } },
                                { kind: "Field", name: { kind: "Name", value: "name" } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<RegisterAccountMutation, RegisterAccountMutationVariables>;
export const CreateEnvironmentDocument = {
    __meta__: { hash: "40b9be4ee57b76949f92ddd50f9a0b129c49d78f" },
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "CreateEnvironment" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "lookup" } },
                    type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
                },
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "name" } },
                    type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
                },
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "description" } },
                    type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
                },
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "deleteProtection" } },
                    type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
                    defaultValue: { kind: "BooleanValue", value: false },
                },
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "upstream" } },
                    type: { kind: "NamedType", name: { kind: "Name", value: "NodeReference" } },
                },
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "color" } },
                    type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "__typename" } },
                    {
                        kind: "Field",
                        alias: { kind: "Name", value: "createEnvironment" },
                        name: { kind: "Name", value: "createEnvironment2" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "data" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: { kind: "Name", value: "lookup" },
                                            value: { kind: "Variable", name: { kind: "Name", value: "lookup" } },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: { kind: "Name", value: "name" },
                                            value: { kind: "Variable", name: { kind: "Name", value: "name" } },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: { kind: "Name", value: "description" },
                                            value: { kind: "Variable", name: { kind: "Name", value: "description" } },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: { kind: "Name", value: "deleteProtection" },
                                            value: {
                                                kind: "Variable",
                                                name: { kind: "Name", value: "deleteProtection" },
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: { kind: "Name", value: "upstream" },
                                            value: { kind: "Variable", name: { kind: "Name", value: "upstream" } },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: { kind: "Name", value: "color" },
                                            value: { kind: "Variable", name: { kind: "Name", value: "color" } },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                { kind: "Field", name: { kind: "Name", value: "id" } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<CreateEnvironmentMutation, CreateEnvironmentMutationVariables>;
export const EnablePushNotificationsDocument = {
    __meta__: { hash: "d269e6aac52c88c42e3f84d9bd2447742c4ab0c3" },
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "EnablePushNotifications" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "endpoint" } },
                    type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
                },
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "expirationTime" } },
                    type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
                },
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "auth" } },
                    type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
                },
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "p256dh" } },
                    type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "__typename" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "enablePushNotifications" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "endpoint" },
                                value: { kind: "Variable", name: { kind: "Name", value: "endpoint" } },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "expirationTime" },
                                value: { kind: "Variable", name: { kind: "Name", value: "expirationTime" } },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "auth" },
                                value: { kind: "Variable", name: { kind: "Name", value: "auth" } },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "p256dh" },
                                value: { kind: "Variable", name: { kind: "Name", value: "p256dh" } },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                { kind: "Field", name: { kind: "Name", value: "id" } },
                                { kind: "Field", name: { kind: "Name", value: "pushEnabled" } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<EnablePushNotificationsMutation, EnablePushNotificationsMutationVariables>;
export const DisablePushNotificationsDocument = {
    __meta__: { hash: "3fb8912958cf69c17f18fdebfa1ecb9c7abd7cd2" },
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "DisablePushNotifications" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "deviceId" } },
                    type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "__typename" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "disablePushNotifications" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "deviceId" },
                                value: { kind: "Variable", name: { kind: "Name", value: "deviceId" } },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                { kind: "Field", name: { kind: "Name", value: "id" } },
                                { kind: "Field", name: { kind: "Name", value: "pushEnabled" } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<DisablePushNotificationsMutation, DisablePushNotificationsMutationVariables>;
export const UseLoginStateProvidersDocument = {
    __meta__: { hash: "909571defba7fc5ff19c7b8c03d4eab93ed39e37" },
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "UseLoginStateProviders" },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "__typename" } },
                    {
                        kind: "Field",
                        alias: { kind: "Name", value: "iamSettings" },
                        name: { kind: "Name", value: "fetchIamSettings" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "by" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: { kind: "Name", value: "id" },
                                            value: { kind: "StringValue", value: "IamSettings", block: false },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                { kind: "Field", name: { kind: "Name", value: "id" } },
                                { kind: "Field", name: { kind: "Name", value: "pinEnabled" } },
                                { kind: "Field", name: { kind: "Name", value: "oauthEnabled" } },
                                { kind: "Field", name: { kind: "Name", value: "passkeyEnabled" } },
                                { kind: "Field", name: { kind: "Name", value: "trustedDeviceEnabled" } },
                                { kind: "Field", name: { kind: "Name", value: "message" } },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        alias: { kind: "Name", value: "listOAuthProviders" },
                        name: { kind: "Name", value: "listOAuthProvider2s" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "sort" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: { kind: "Name", value: "by" },
                                            value: {
                                                kind: "ListValue",
                                                values: [{ kind: "EnumValue", value: "sort" }],
                                            },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: { kind: "Name", value: "order" },
                                            value: { kind: "EnumValue", value: "asc" },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                { kind: "Field", name: { kind: "Name", value: "id" } },
                                { kind: "Field", name: { kind: "Name", value: "enabled" } },
                                { kind: "Field", name: { kind: "Name", value: "clientId" } },
                                { kind: "Field", name: { kind: "Name", value: "authorizationEndpoint" } },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "params" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                            { kind: "Field", name: { kind: "Name", value: "key" } },
                                            { kind: "Field", name: { kind: "Name", value: "value" } },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "authenticator" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                            { kind: "Field", name: { kind: "Name", value: "id" } },
                                            { kind: "Field", name: { kind: "Name", value: "name" } },
                                            { kind: "Field", name: { kind: "Name", value: "loginWithLabel" } },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "darkMode" },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                                        { kind: "Field", name: { kind: "Name", value: "fontFamily" } },
                                                        { kind: "Field", name: { kind: "Name", value: "fontWeight" } },
                                                        { kind: "Field", name: { kind: "Name", value: "textColor" } },
                                                        {
                                                            kind: "Field",
                                                            name: { kind: "Name", value: "backgroundColor" },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: { kind: "Name", value: "backgroundHoverColor" },
                                                        },
                                                        { kind: "Field", name: { kind: "Name", value: "iconData" } },
                                                    ],
                                                },
                                            },
                                            {
                                                kind: "Field",
                                                name: { kind: "Name", value: "lightMode" },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                                        { kind: "Field", name: { kind: "Name", value: "fontFamily" } },
                                                        { kind: "Field", name: { kind: "Name", value: "fontWeight" } },
                                                        { kind: "Field", name: { kind: "Name", value: "textColor" } },
                                                        {
                                                            kind: "Field",
                                                            name: { kind: "Name", value: "backgroundColor" },
                                                        },
                                                        {
                                                            kind: "Field",
                                                            name: { kind: "Name", value: "backgroundHoverColor" },
                                                        },
                                                        { kind: "Field", name: { kind: "Name", value: "iconData" } },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<UseLoginStateProvidersQuery, UseLoginStateProvidersQueryVariables>;
export const LoginWithPartnerCodeDocument = {
    __meta__: { hash: "16b6092c97d22bb172dfcf7d506872e4776cff34" },
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "LoginWithPartnerCode" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "provider" } },
                    type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
                },
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "code" } },
                    type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
                },
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "referrer" } },
                    type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
                },
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "rememberMe" } },
                    type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
                },
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "d4d27456" } },
                    type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
                    defaultValue: { kind: "BooleanValue", value: false },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "__typename" } },
                    {
                        kind: "Field",
                        alias: { kind: "Name", value: "session" },
                        name: { kind: "Name", value: "loginWithPartnerCode" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "provider" },
                                value: { kind: "Variable", name: { kind: "Name", value: "provider" } },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "code" },
                                value: { kind: "Variable", name: { kind: "Name", value: "code" } },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "referrer" },
                                value: { kind: "Variable", name: { kind: "Name", value: "referrer" } },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "rememberMe" },
                                value: { kind: "Variable", name: { kind: "Name", value: "rememberMe" } },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                { kind: "Field", name: { kind: "Name", value: "id" } },
                                { kind: "Field", name: { kind: "Name", value: "type" } },
                                { kind: "Field", name: { kind: "Name", value: "state" } },
                                { kind: "Field", name: { kind: "Name", value: "challenge" } },
                                { kind: "Field", name: { kind: "Name", value: "expiresAt" } },
                                {
                                    kind: "InlineFragment",
                                    directives: [
                                        {
                                            kind: "Directive",
                                            name: { kind: "Name", value: "include" },
                                            arguments: [
                                                {
                                                    kind: "Argument",
                                                    name: { kind: "Name", value: "if" },
                                                    value: {
                                                        kind: "Variable",
                                                        name: { kind: "Name", value: "d4d27456" },
                                                    },
                                                },
                                            ],
                                        },
                                    ],
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                            {
                                                kind: "Field",
                                                alias: { kind: "Name", value: "_eid" },
                                                name: { kind: "Name", value: "_emailSecretId" },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<LoginWithPartnerCodeMutation, LoginWithPartnerCodeMutationVariables>;
export const FetchAuthSessionDocument = {
    __meta__: { hash: "bd387ac4eedee968af4ee4919845274a92c61128" },
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "FetchAuthSession" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
                    type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ID" } } },
                },
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "d4d27456" } },
                    type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
                    defaultValue: { kind: "BooleanValue", value: false },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "__typename" } },
                    {
                        kind: "Field",
                        alias: { kind: "Name", value: "session" },
                        name: { kind: "Name", value: "fetchAuthSession" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "sessionId" },
                                value: { kind: "Variable", name: { kind: "Name", value: "id" } },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                { kind: "Field", name: { kind: "Name", value: "id" } },
                                { kind: "Field", name: { kind: "Name", value: "type" } },
                                { kind: "Field", name: { kind: "Name", value: "state" } },
                                { kind: "Field", name: { kind: "Name", value: "challenge" } },
                                { kind: "Field", name: { kind: "Name", value: "expiresAt" } },
                                {
                                    kind: "InlineFragment",
                                    directives: [
                                        {
                                            kind: "Directive",
                                            name: { kind: "Name", value: "include" },
                                            arguments: [
                                                {
                                                    kind: "Argument",
                                                    name: { kind: "Name", value: "if" },
                                                    value: {
                                                        kind: "Variable",
                                                        name: { kind: "Name", value: "d4d27456" },
                                                    },
                                                },
                                            ],
                                        },
                                    ],
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                            {
                                                kind: "Field",
                                                alias: { kind: "Name", value: "_eid" },
                                                name: { kind: "Name", value: "_emailSecretId" },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<FetchAuthSessionQuery, FetchAuthSessionQueryVariables>;
export const StartAuthSessionDocument = {
    __meta__: { hash: "d4583ee56946dd0f54fc9863001a8c9ed1e64516" },
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "StartAuthSession" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "email" } },
                    type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
                },
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "rememberMe" } },
                    type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "__typename" } },
                    {
                        kind: "Field",
                        alias: { kind: "Name", value: "session" },
                        name: { kind: "Name", value: "startAuthSession" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "email" },
                                value: { kind: "Variable", name: { kind: "Name", value: "email" } },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "rememberMe" },
                                value: { kind: "Variable", name: { kind: "Name", value: "rememberMe" } },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                { kind: "Field", name: { kind: "Name", value: "id" } },
                                { kind: "Field", name: { kind: "Name", value: "type" } },
                                { kind: "Field", name: { kind: "Name", value: "challenge" } },
                                { kind: "Field", name: { kind: "Name", value: "expiresAt" } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<StartAuthSessionMutation, StartAuthSessionMutationVariables>;
export const RegisterPasskeyDocument = {
    __meta__: { hash: "5f01940da24d3ac264fa1656f6a0112357cbe882" },
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "RegisterPasskey" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "sessionId" } },
                    type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ID" } } },
                },
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "referrer" } },
                    type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
                },
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "registration" } },
                    type: {
                        kind: "NonNullType",
                        type: { kind: "NamedType", name: { kind: "Name", value: "JSONObject" } },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "__typename" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "registerPasskey" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "sessionId" },
                                value: { kind: "Variable", name: { kind: "Name", value: "sessionId" } },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "referrer" },
                                value: { kind: "Variable", name: { kind: "Name", value: "referrer" } },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "registration" },
                                value: { kind: "Variable", name: { kind: "Name", value: "registration" } },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<RegisterPasskeyMutation, RegisterPasskeyMutationVariables>;
export const LoginWithPasskeyDocument = {
    __meta__: { hash: "ef7b5dd6dcba1a3d4b24d6a230030cc58119d930" },
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "LoginWithPasskey" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "sessionId" } },
                    type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ID" } } },
                },
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "referrer" } },
                    type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
                },
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "rememberMe" } },
                    type: {
                        kind: "NonNullType",
                        type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
                    },
                },
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "authentication" } },
                    type: {
                        kind: "NonNullType",
                        type: { kind: "NamedType", name: { kind: "Name", value: "JSONObject" } },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "__typename" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "loginWithPasskey" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "sessionId" },
                                value: { kind: "Variable", name: { kind: "Name", value: "sessionId" } },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "referrer" },
                                value: { kind: "Variable", name: { kind: "Name", value: "referrer" } },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "rememberMe" },
                                value: { kind: "Variable", name: { kind: "Name", value: "rememberMe" } },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "authentication" },
                                value: { kind: "Variable", name: { kind: "Name", value: "authentication" } },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<LoginWithPasskeyMutation, LoginWithPasskeyMutationVariables>;
export const ActivateAuthSessionDocument = {
    __meta__: { hash: "f6b263cef90b0e6f93e8fa2b0bcfe458a5c8f05c" },
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "ActivateAuthSession" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
                    type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ID" } } },
                },
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "state" } },
                    type: {
                        kind: "NonNullType",
                        type: { kind: "NamedType", name: { kind: "Name", value: "AuthSessionState" } },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "__typename" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "activateAuthSession" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "sessionId" },
                                value: { kind: "Variable", name: { kind: "Name", value: "id" } },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "state" },
                                value: { kind: "Variable", name: { kind: "Name", value: "state" } },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                { kind: "Field", name: { kind: "Name", value: "pin" } },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "session" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                            { kind: "Field", name: { kind: "Name", value: "id" } },
                                            { kind: "Field", name: { kind: "Name", value: "type" } },
                                            { kind: "Field", name: { kind: "Name", value: "challenge" } },
                                            { kind: "Field", name: { kind: "Name", value: "expiresAt" } },
                                            { kind: "Field", name: { kind: "Name", value: "state" } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<ActivateAuthSessionMutation, ActivateAuthSessionMutationVariables>;
export const ConfirmAuthSessionDocument = {
    __meta__: { hash: "b883905742475db596842a9f220503eef79602ab" },
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "ConfirmAuthSession" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
                    type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ID" } } },
                },
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "qrcode" } },
                    type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "__typename" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "confirmAuthSession" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "sessionId" },
                                value: { kind: "Variable", name: { kind: "Name", value: "id" } },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "qrcode" },
                                value: { kind: "Variable", name: { kind: "Name", value: "qrcode" } },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                { kind: "Field", name: { kind: "Name", value: "id" } },
                                { kind: "Field", name: { kind: "Name", value: "type" } },
                                { kind: "Field", name: { kind: "Name", value: "challenge" } },
                                { kind: "Field", name: { kind: "Name", value: "expiresAt" } },
                                { kind: "Field", name: { kind: "Name", value: "state" } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<ConfirmAuthSessionMutation, ConfirmAuthSessionMutationVariables>;
export const FetchAuthSessionWithCodeDocument = {
    __meta__: { hash: "027d20940b682e095f8b6c84bcc51bba353678dd" },
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "FetchAuthSessionWithCode" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
                    type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ID" } } },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "__typename" } },
                    {
                        kind: "Field",
                        alias: { kind: "Name", value: "session" },
                        name: { kind: "Name", value: "fetchAuthSession" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "sessionId" },
                                value: { kind: "Variable", name: { kind: "Name", value: "id" } },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                { kind: "Field", name: { kind: "Name", value: "id" } },
                                { kind: "Field", name: { kind: "Name", value: "type" } },
                                { kind: "Field", name: { kind: "Name", value: "challenge" } },
                                { kind: "Field", name: { kind: "Name", value: "expiresAt" } },
                                { kind: "Field", name: { kind: "Name", value: "state" } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<FetchAuthSessionWithCodeQuery, FetchAuthSessionWithCodeQueryVariables>;
export const LoginWithEmailCodeDocument = {
    __meta__: { hash: "ab65dcdaafe547057b234362e2c369e48e79b4f4" },
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "LoginWithEmailCode" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
                    type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ID" } } },
                },
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "code" } },
                    type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "__typename" } },
                    {
                        kind: "Field",
                        alias: { kind: "Name", value: "session" },
                        name: { kind: "Name", value: "loginWithEmailCode" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "sessionId" },
                                value: { kind: "Variable", name: { kind: "Name", value: "id" } },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "code" },
                                value: { kind: "Variable", name: { kind: "Name", value: "code" } },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<LoginWithEmailCodeMutation, LoginWithEmailCodeMutationVariables>;
export const CreateProjectDocument = {
    __meta__: { hash: "ac9cc9b3c4b991a1e2eb90bc4a6ae741783e52cc" },
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "CreateProject" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "lookup" } },
                    type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
                },
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "name" } },
                    type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
                },
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "type" } },
                    type: {
                        kind: "NonNullType",
                        type: { kind: "NamedType", name: { kind: "Name", value: "Project2Type" } },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "__typename" } },
                    {
                        kind: "Field",
                        alias: { kind: "Name", value: "createProject" },
                        name: { kind: "Name", value: "createProject2" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "data" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: { kind: "Name", value: "lookup" },
                                            value: { kind: "Variable", name: { kind: "Name", value: "lookup" } },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: { kind: "Name", value: "name" },
                                            value: { kind: "Variable", name: { kind: "Name", value: "name" } },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: { kind: "Name", value: "type" },
                                            value: { kind: "Variable", name: { kind: "Name", value: "type" } },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                { kind: "Field", name: { kind: "Name", value: "id" } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<CreateProjectMutation, CreateProjectMutationVariables>;
export const UseDataProjectSchemaDocument = {
    __meta__: { hash: "8dd2c8b100c1bf5bbdbfcc9e7d615e26ec0788d1" },
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "UseDataProjectSchema" },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "__typename" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "listSystemTypes" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                { kind: "Field", name: { kind: "Name", value: "id" } },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "types" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                            { kind: "Field", name: { kind: "Name", value: "id" } },
                                            {
                                                kind: "InlineFragment",
                                                typeCondition: {
                                                    kind: "NamedType",
                                                    name: { kind: "Name", value: "_SystemTypeComplex" },
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                                        {
                                                            kind: "Field",
                                                            name: { kind: "Name", value: "fields" },
                                                            selectionSet: {
                                                                kind: "SelectionSet",
                                                                selections: [
                                                                    {
                                                                        kind: "Field",
                                                                        name: { kind: "Name", value: "__typename" },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: { kind: "Name", value: "name" },
                                                                    },
                                                                    {
                                                                        kind: "Field",
                                                                        name: { kind: "Name", value: "type" },
                                                                    },
                                                                ],
                                                            },
                                                        },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "collection" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                            { kind: "Field", name: { kind: "Name", value: "id" } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<UseDataProjectSchemaQuery, UseDataProjectSchemaQueryVariables>;
export const UnlinkIdentityProviderDocument = {
    __meta__: { hash: "ecca7c627cf0e8c20503239a4409baad0f49badf" },
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "UnlinkIdentityProvider" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
                    type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ID" } } },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "__typename" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "unlinkIdentityProvider" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "providerId" },
                                value: { kind: "Variable", name: { kind: "Name", value: "id" } },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<UnlinkIdentityProviderMutation, UnlinkIdentityProviderMutationVariables>;
export const CreateEmailVerificationCodeDocument = {
    __meta__: { hash: "84d2e1c1e1f5fcbe19125796f6b8fb48a3330757" },
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "CreateEmailVerificationCode" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "email" } },
                    type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "__typename" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "createEmailVerificationCode" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "email" },
                                value: { kind: "Variable", name: { kind: "Name", value: "email" } },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<CreateEmailVerificationCodeMutation, CreateEmailVerificationCodeMutationVariables>;
export const ConfirmEmailVerificationCodeDocument = {
    __meta__: { hash: "bb118200d8a6b040eb5cbbccf252a3ac90bd496b" },
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "ConfirmEmailVerificationCode" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "email" } },
                    type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
                },
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "code" } },
                    type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "__typename" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "verifyEmail" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "email" },
                                value: { kind: "Variable", name: { kind: "Name", value: "email" } },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "code" },
                                value: { kind: "Variable", name: { kind: "Name", value: "code" } },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<ConfirmEmailVerificationCodeMutation, ConfirmEmailVerificationCodeMutationVariables>;
export const DeletePendingEmailVerificationDocument = {
    __meta__: { hash: "11bf7f6b43bdbd6f8bca34ebaeb6690f24fb5e40" },
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "DeletePendingEmailVerification" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
                    type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ID" } } },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "__typename" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "deletePendingEmailVerification" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "having" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: { kind: "Name", value: "id" },
                                            value: { kind: "Variable", name: { kind: "Name", value: "id" } },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                { kind: "Field", name: { kind: "Name", value: "id" } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<DeletePendingEmailVerificationMutation, DeletePendingEmailVerificationMutationVariables>;
export const UpdateAccountEmailSettingsDocument = {
    __meta__: { hash: "5e8db40880f999dd531ac7195fc009d02f6f78f6" },
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "UpdateAccountEmailSettings" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "email" } },
                    type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
                },
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "pinEnabled" } },
                    type: {
                        kind: "NonNullType",
                        type: { kind: "NamedType", name: { kind: "Name", value: "Boolean" } },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "__typename" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "updateAccountEmailSettings" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "email" },
                                value: { kind: "Variable", name: { kind: "Name", value: "email" } },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "pinEnabled" },
                                value: { kind: "Variable", name: { kind: "Name", value: "pinEnabled" } },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                { kind: "Field", name: { kind: "Name", value: "id" } },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "info" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                            {
                                                kind: "InlineFragment",
                                                typeCondition: {
                                                    kind: "NamedType",
                                                    name: { kind: "Name", value: "UserLookupEmail" },
                                                },
                                                selectionSet: {
                                                    kind: "SelectionSet",
                                                    selections: [
                                                        { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                                        { kind: "Field", name: { kind: "Name", value: "pinEnabled" } },
                                                    ],
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<UpdateAccountEmailSettingsMutation, UpdateAccountEmailSettingsMutationVariables>;
export const RegisterPartnerCodeDocument = {
    __meta__: { hash: "730b62326d39030aaa75536339cc064b7d43f7ec" },
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "RegisterPartnerCode" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "provider" } },
                    type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
                },
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "code" } },
                    type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
                },
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "referrer" } },
                    type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "__typename" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "registerPartnerCode" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "provider" },
                                value: { kind: "Variable", name: { kind: "Name", value: "provider" } },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "code" },
                                value: { kind: "Variable", name: { kind: "Name", value: "code" } },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "referrer" },
                                value: { kind: "Variable", name: { kind: "Name", value: "referrer" } },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<RegisterPartnerCodeMutation, RegisterPartnerCodeMutationVariables>;
export const DeleteTrustedDeviceDocument = {
    __meta__: { hash: "2ae368ad9c33a7a8d05518e75220a568e8e0614c" },
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "DeleteTrustedDevice" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
                    type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ID" } } },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "__typename" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "deleteTrustedDevice" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "having" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: { kind: "Name", value: "id" },
                                            value: { kind: "Variable", name: { kind: "Name", value: "id" } },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                { kind: "Field", name: { kind: "Name", value: "id" } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<DeleteTrustedDeviceMutation, DeleteTrustedDeviceMutationVariables>;
export const TestPushNotificationsDocument = {
    __meta__: { hash: "5b31061863f4091742f1e0fa758f04f82ed83eae" },
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "TestPushNotifications" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "deviceId" } },
                    type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "__typename" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "testPushNotifications" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "deviceId" },
                                value: { kind: "Variable", name: { kind: "Name", value: "deviceId" } },
                            },
                        ],
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<TestPushNotificationsMutation, TestPushNotificationsMutationVariables>;
export const UseDataAccountEnvironmentsDocument = {
    __meta__: { hash: "f2a5d46ab839c219fbc62041322eb0f042a6b993" },
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "useDataAccountEnvironments" },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "__typename" } },
                    {
                        kind: "Field",
                        alias: { kind: "Name", value: "environments" },
                        name: { kind: "Name", value: "listEnvironment2s" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                { kind: "Field", name: { kind: "Name", value: "id" } },
                                { kind: "Field", name: { kind: "Name", value: "name" } },
                                { kind: "Field", name: { kind: "Name", value: "lookup" } },
                                { kind: "Field", name: { kind: "Name", value: "description" } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<UseDataAccountEnvironmentsQuery, UseDataAccountEnvironmentsQueryVariables>;
export const UseDataAccountProjectsDocument = {
    __meta__: { hash: "a4f659e9997ad52f1fd25fb4d0b025a7640d5243" },
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "useDataAccountProjects" },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "__typename" } },
                    {
                        kind: "Field",
                        alias: { kind: "Name", value: "projects" },
                        name: { kind: "Name", value: "listProject2s" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                { kind: "Field", name: { kind: "Name", value: "id" } },
                                { kind: "Field", name: { kind: "Name", value: "name" } },
                                { kind: "Field", name: { kind: "Name", value: "lookup" } },
                                { kind: "Field", name: { kind: "Name", value: "type" } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<UseDataAccountProjectsQuery, UseDataAccountProjectsQueryVariables>;
export const UseDataUserAccountsDocument = {
    __meta__: { hash: "0a87c1c2b9ddbfd75710c391d7027036ae257ed7" },
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "useDataUserAccounts" },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "__typename" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "listUserAccounts" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                { kind: "Field", name: { kind: "Name", value: "id" } },
                                { kind: "Field", name: { kind: "Name", value: "name" } },
                                { kind: "Field", name: { kind: "Name", value: "lookup" } },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "projects" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                            { kind: "Field", name: { kind: "Name", value: "id" } },
                                            { kind: "Field", name: { kind: "Name", value: "name" } },
                                            { kind: "Field", name: { kind: "Name", value: "lookup" } },
                                            { kind: "Field", name: { kind: "Name", value: "type" } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<UseDataUserAccountsQuery, UseDataUserAccountsQueryVariables>;
export const LogoutDocument = {
    __meta__: { hash: "f70e5fca72f6a023726bcbebe170ef78b7795a36" },
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "Logout" },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "__typename" } },
                    { kind: "Field", name: { kind: "Name", value: "logout" } },
                ],
            },
        },
    ],
} as unknown as DocumentNode<LogoutMutation, LogoutMutationVariables>;
export const UseMe_QueryDocument = {
    __meta__: { hash: "b9829eef7397f0aad2685617f7f405b42c3848eb" },
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "useMe_Query" },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "__typename" } },
                    {
                        kind: "Field",
                        alias: { kind: "Name", value: "user" },
                        name: { kind: "Name", value: "fetchUser" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                { kind: "Field", name: { kind: "Name", value: "id" } },
                                { kind: "Field", name: { kind: "Name", value: "firstName" } },
                                { kind: "Field", name: { kind: "Name", value: "lastName" } },
                                { kind: "Field", name: { kind: "Name", value: "primaryEmail" } },
                                { kind: "Field", name: { kind: "Name", value: "profilePicture" } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<UseMe_QueryQuery, UseMe_QueryQueryVariables>;
export const RefreshLoginDocument = {
    __meta__: { hash: "b0b994a89f26bc93185e8b6156de865d77d0dfdd" },
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "RefreshLogin" },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "__typename" } },
                    { kind: "Field", name: { kind: "Name", value: "refreshToken" } },
                ],
            },
        },
    ],
} as unknown as DocumentNode<RefreshLoginMutation, RefreshLoginMutationVariables>;
export const SettingsMePage_InfoQueryDocument = {
    __meta__: { hash: "9de9207710f64281126d5e7d6f5f0ee3c39a98c5" },
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "SettingsMePage_InfoQuery" },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "__typename" } },
                    {
                        kind: "Field",
                        alias: { kind: "Name", value: "user" },
                        name: { kind: "Name", value: "fetchUser" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                { kind: "Field", name: { kind: "Name", value: "id" } },
                                { kind: "Field", name: { kind: "Name", value: "firstName" } },
                                { kind: "Field", name: { kind: "Name", value: "lastName" } },
                                { kind: "Field", name: { kind: "Name", value: "primaryEmail" } },
                                { kind: "Field", name: { kind: "Name", value: "profilePicture" } },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "linkedIdentities" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                            { kind: "Field", name: { kind: "Name", value: "id" } },
                                            { kind: "Field", name: { kind: "Name", value: "provider" } },
                                            {
                                                kind: "FragmentSpread",
                                                name: { kind: "Name", value: "IdentityProvider" },
                                            },
                                            {
                                                kind: "FragmentSpread",
                                                name: { kind: "Name", value: "IdentityProvider2" },
                                            },
                                            {
                                                kind: "FragmentSpread",
                                                name: { kind: "Name", value: "IdentityProviderEmail" },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        alias: { kind: "Name", value: "tokens" },
                        name: { kind: "Name", value: "listTrustedDevices" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "sort" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: { kind: "Name", value: "by" },
                                            value: { kind: "EnumValue", value: "_updatedAt" },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: { kind: "Name", value: "order" },
                                            value: { kind: "EnumValue", value: "desc" },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                { kind: "FragmentSpread", name: { kind: "Name", value: "TrustedDeviceList" } },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        alias: { kind: "Name", value: "pending" },
                        name: { kind: "Name", value: "listPendingEmailVerifications" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "sort" },
                                value: {
                                    kind: "ObjectValue",
                                    fields: [
                                        {
                                            kind: "ObjectField",
                                            name: { kind: "Name", value: "by" },
                                            value: { kind: "EnumValue", value: "_updatedAt" },
                                        },
                                        {
                                            kind: "ObjectField",
                                            name: { kind: "Name", value: "order" },
                                            value: { kind: "EnumValue", value: "desc" },
                                        },
                                    ],
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                { kind: "FragmentSpread", name: { kind: "Name", value: "PendingEmailVerification" } },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "IdentityProvider" },
            typeCondition: { kind: "NamedType", name: { kind: "Name", value: "UserLookup" } },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "__typename" } },
                    { kind: "Field", name: { kind: "Name", value: "id" } },
                    { kind: "Field", name: { kind: "Name", value: "provider" } },
                    { kind: "Field", name: { kind: "Name", value: "displayName" } },
                    { kind: "Field", name: { kind: "Name", value: "displayAvatar" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "usageInfo" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                { kind: "Field", name: { kind: "Name", value: "deviceName" } },
                                { kind: "Field", name: { kind: "Name", value: "deviceFormFactor" } },
                                { kind: "Field", name: { kind: "Name", value: "ipCountry" } },
                                { kind: "Field", name: { kind: "Name", value: "lastUsed" } },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "authenticator" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                { kind: "Field", name: { kind: "Name", value: "id" } },
                                { kind: "Field", name: { kind: "Name", value: "name" } },
                                { kind: "Field", name: { kind: "Name", value: "loginWithLabel" } },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "darkMode" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                            { kind: "Field", name: { kind: "Name", value: "fontFamily" } },
                                            { kind: "Field", name: { kind: "Name", value: "fontWeight" } },
                                            { kind: "Field", name: { kind: "Name", value: "textColor" } },
                                            { kind: "Field", name: { kind: "Name", value: "backgroundColor" } },
                                            { kind: "Field", name: { kind: "Name", value: "iconData" } },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lightMode" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                            { kind: "Field", name: { kind: "Name", value: "fontFamily" } },
                                            { kind: "Field", name: { kind: "Name", value: "fontWeight" } },
                                            { kind: "Field", name: { kind: "Name", value: "textColor" } },
                                            { kind: "Field", name: { kind: "Name", value: "backgroundColor" } },
                                            { kind: "Field", name: { kind: "Name", value: "iconData" } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "IdentityProvider2" },
            typeCondition: { kind: "NamedType", name: { kind: "Name", value: "UserLookup" } },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "__typename" } },
                    { kind: "Field", name: { kind: "Name", value: "id" } },
                    { kind: "Field", name: { kind: "Name", value: "provider" } },
                    { kind: "Field", name: { kind: "Name", value: "displayName" } },
                    { kind: "Field", name: { kind: "Name", value: "displayAvatar" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "usageInfo" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                { kind: "Field", name: { kind: "Name", value: "deviceName" } },
                                { kind: "Field", name: { kind: "Name", value: "deviceFormFactor" } },
                                { kind: "Field", name: { kind: "Name", value: "ipCountry" } },
                                { kind: "Field", name: { kind: "Name", value: "lastUsed" } },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "authenticator" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                { kind: "Field", name: { kind: "Name", value: "id" } },
                                { kind: "Field", name: { kind: "Name", value: "name" } },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "darkMode" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                            { kind: "Field", name: { kind: "Name", value: "iconData" } },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "lightMode" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                            { kind: "Field", name: { kind: "Name", value: "iconData" } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "IdentityProviderEmail" },
            typeCondition: { kind: "NamedType", name: { kind: "Name", value: "UserLookup" } },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "__typename" } },
                    { kind: "Field", name: { kind: "Name", value: "id" } },
                    { kind: "Field", name: { kind: "Name", value: "provider" } },
                    { kind: "Field", name: { kind: "Name", value: "displayName" } },
                    { kind: "Field", name: { kind: "Name", value: "displayAvatar" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "usageInfo" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                { kind: "Field", name: { kind: "Name", value: "lastUsed" } },
                            ],
                        },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "info" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                {
                                    kind: "InlineFragment",
                                    typeCondition: {
                                        kind: "NamedType",
                                        name: { kind: "Name", value: "UserLookupEmail" },
                                    },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                            { kind: "Field", name: { kind: "Name", value: "pinEnabled" } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "TrustedDeviceList" },
            typeCondition: { kind: "NamedType", name: { kind: "Name", value: "TrustedDevice" } },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "__typename" } },
                    { kind: "Field", name: { kind: "Name", value: "id" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "usageInfo" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                { kind: "Field", name: { kind: "Name", value: "deviceName" } },
                                { kind: "Field", name: { kind: "Name", value: "deviceFormFactor" } },
                                { kind: "Field", name: { kind: "Name", value: "ipCountry" } },
                                { kind: "Field", name: { kind: "Name", value: "lastUsed" } },
                            ],
                        },
                    },
                    { kind: "Field", name: { kind: "Name", value: "currentSession" } },
                    { kind: "Field", name: { kind: "Name", value: "pushEnabled" } },
                ],
            },
        },
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "PendingEmailVerification" },
            typeCondition: { kind: "NamedType", name: { kind: "Name", value: "PendingEmailVerification" } },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "__typename" } },
                    { kind: "Field", name: { kind: "Name", value: "id" } },
                    { kind: "Field", name: { kind: "Name", value: "email" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "_meta" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<SettingsMePage_InfoQueryQuery, SettingsMePage_InfoQueryQueryVariables>;
export const UpdateUserProfileDocument = {
    __meta__: { hash: "57d9a536c7d5806fd67c2cab8705fb2ac24b2672" },
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "updateUserProfile" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "firstName" } },
                    type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
                },
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "lastName" } },
                    type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
                },
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "primaryEmail" } },
                    type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
                },
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "profilePicture" } },
                    type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "__typename" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "updateUserProfile" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "firstName" },
                                value: { kind: "Variable", name: { kind: "Name", value: "firstName" } },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "lastName" },
                                value: { kind: "Variable", name: { kind: "Name", value: "lastName" } },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "primaryEmail" },
                                value: { kind: "Variable", name: { kind: "Name", value: "primaryEmail" } },
                            },
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "profilePicture" },
                                value: { kind: "Variable", name: { kind: "Name", value: "profilePicture" } },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                { kind: "Field", name: { kind: "Name", value: "id" } },
                                { kind: "Field", name: { kind: "Name", value: "firstName" } },
                                { kind: "Field", name: { kind: "Name", value: "lastName" } },
                                { kind: "Field", name: { kind: "Name", value: "primaryEmail" } },
                                { kind: "Field", name: { kind: "Name", value: "profilePicture" } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>;
export const AccountSettingsPage_DeleteDocument = {
    __meta__: { hash: "c13d51ab7331e569d86fe676b430aa7fd65340ff" },
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "AccountSettingsPage_Delete" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: { kind: "Variable", name: { kind: "Name", value: "slug" } },
                    type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "String" } } },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "__typename" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "deleteAccount" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "slug" },
                                value: { kind: "Variable", name: { kind: "Name", value: "slug" } },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                { kind: "Field", name: { kind: "Name", value: "id" } },
                                { kind: "Field", name: { kind: "Name", value: "deleting" } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<AccountSettingsPage_DeleteMutation, AccountSettingsPage_DeleteMutationVariables>;
export const ListCollectionsDocument = {
    __meta__: { hash: "77a24c03d4544c01b2fe50f58f6439d4ee3d5712" },
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "ListCollections" },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "__typename" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "listCollections" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                { kind: "Field", name: { kind: "Name", value: "id" } },
                                { kind: "Field", name: { kind: "Name", value: "description" } },
                                { kind: "Field", name: { kind: "Name", value: "partitioned" } },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "partitionedBy" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                            { kind: "Field", name: { kind: "Name", value: "id" } },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<ListCollectionsQuery, ListCollectionsQueryVariables>;
export const AccountHome_QueryDocument = {
    __meta__: { hash: "8e285fa0fdc74469ec0e4816bdc2f1e03218f7da" },
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "AccountHome_Query" },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "__typename" } },
                    {
                        kind: "Field",
                        alias: { kind: "Name", value: "account" },
                        name: { kind: "Name", value: "fetchAccount" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                                { kind: "Field", name: { kind: "Name", value: "id" } },
                                { kind: "Field", name: { kind: "Name", value: "name" } },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<AccountHome_QueryQuery, AccountHome_QueryQueryVariables>;
