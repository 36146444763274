import { AlertLoader } from "@/components/FullScreenLoader";
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { sendErrorReport } from "@/lib/errors";
import type { GraphQLError } from "graphql";
import { CircleCheckBig, Globe, OctagonMinus, TriangleAlert } from "lucide-react";
import { CombinedError } from "urql";

const DEFAULT_CLASSIFICATIONS: Record<string, ErrorClassification> = {};

interface ErrorClassification {
    severity: "temporary" | "error";
    icon: "network" | "alert" | "stop";
    title: string;
    description?: string | string[];
}

export class UserError extends Error {
    private classification: ErrorClassification;

    constructor(nameOrClassification: string | ErrorClassification) {
        super(typeof nameOrClassification === "string" ? nameOrClassification : nameOrClassification.title);

        if (typeof nameOrClassification !== "string") {
            this.classification = nameOrClassification;
        } else {
            this.classification = messageToClassification(nameOrClassification, false);
        }
    }

    toClassification() {
        return this.classification;
    }
}

function messageToClassification(message: string, hideError = true): ErrorClassification {
    let classification = DEFAULT_CLASSIFICATIONS[message];

    if (!classification) {
        console.error("Unknown Error", message);
        classification = {
            severity: "error",
            icon: "stop",
            title: hideError ? "Unknown Error" : message,
            description: hideError
                ? "Something went wrong. Please try again later."
                : "An unexpected error occurred. Please try again later.",
        };
    }

    return classification;
}

function classifyError(error: CombinedError | UserError | Error): ErrorClassification {
    if (error instanceof UserError) {
        return error.toClassification();
    }

    if (error instanceof CombinedError) {
        // With network errors we can say to retry
        if (error.networkError) {
            return {
                severity: "temporary",
                title: "Connection Lost",
                description: "An network error occurred. Please try again later.",
                icon: "network",
            };
        }

        // Graphql errors are much more complex
        if (error.graphQLErrors) {
            if (error.graphQLErrors.some((e) => e.extensions?.code === "UNAUTHENTICATED")) {
                return {
                    severity: "temporary",
                    title: "Authentication Required",
                    description: "Please log in to continue.",
                    icon: "alert",
                };
            }

            const uniqueErrors: GraphQLError[] = [];
            for (const graphQLError of error.graphQLErrors) {
                if (!uniqueErrors.some((e) => e.message === graphQLError.message)) {
                    uniqueErrors.push(graphQLError);
                }
            }

            if (uniqueErrors.length === 1) {
                const firstError = uniqueErrors[0];
                return {
                    severity: "error",
                    title: firstError.message,
                    description: firstError.extensions?.description as string | undefined,
                    icon: "alert",
                };
            }

            return {
                severity: "error",
                title: "Something Went Wrong",
                description: uniqueErrors.map((e) => e.message),
                icon: "stop",
            };
        }
    }

    return messageToClassification(error.message);
}

const icons = {
    network: Globe,
    alert: TriangleAlert,
    stop: OctagonMinus,
};

export function AlertFromError(props: { error?: CombinedError | Error | null }) {
    if (!props.error) {
        return null;
    }

    const classified = classifyError(props.error);
    const AlertIcon = icons[classified.icon];

    sendErrorReport(props.error);

    return (
        <div className="py-3 text-left">
            <Alert
                variant={classified.severity === "error" ? "destructive" : "warning"}
                className="border-x-0 rounded-none"
            >
                <AlertIcon className="h-4 w-4" />
                <AlertTitle>{classified.title}</AlertTitle>
                {classified.description && (
                    <AlertDescription>
                        {Array.isArray(classified.description) &&
                            classified.description.map((err) => <li key={err}>{err}</li>)}
                        {!Array.isArray(classified.description) && classified.description}
                    </AlertDescription>
                )}
            </Alert>
        </div>
    );
}

export function AlertFromMutation(props: {
    title: string;
    description: string;
    loading?: boolean;
    error?: CombinedError | Error | null;
}) {
    if (props.loading) {
        return (
            <div className="py-3 text-left">
                <Alert variant="default" className="border-x-0 rounded-none">
                    <AlertLoader />
                    <AlertTitle className="text-muted-foreground animate-fade">Working hard ...</AlertTitle>
                    <AlertDescription className="text-muted-foreground animate-fade">
                        Just a second or two, max.
                    </AlertDescription>
                </Alert>
            </div>
        );
    }

    if (props.error) {
        return <AlertFromError error={props.error} />;
    }

    return (
        <div className="py-3 text-left">
            <Alert variant="success" className="border-x-0 rounded-none">
                <CircleCheckBig className="h-4 w-4" />
                <AlertTitle>{props.title}</AlertTitle>
                <AlertDescription>{props.description}</AlertDescription>
            </Alert>
        </div>
    );
}
