import { graphql } from "@/generated";
import { useQuery } from "urql";

const useMe_Query = graphql(/* GraphQL */ `
    query useMe_Query {
        user: fetchUser {
            id
            firstName
            lastName
            primaryEmail
            profilePicture
        }
    }
`);

export function useMe() {
    const [response] = useQuery({
        query: useMe_Query,
    });

    return response?.data?.user;
}
