// Generouted, changes to this file will be overriden
/* eslint-disable */

import { components, hooks, utils } from '@generouted/react-router/client'

export type Path =
  | `/`
  | `/-/auth/callback`
  | `/-/auth/email/:id`
  | `/-/settings/*`
  | `/:account`
  | `/:account/-/permissions`
  | `/:account/c/:project`
  | `/:account/c/:project/:branch`
  | `/:account/d/:project`
  | `/:account/d/:project/:branch`
  | `/:account/d/:project/:branch/-/collections`
  | `/:account/d/:project/:branch/-/connect`
  | `/:account/d/:project/:branch/-/permissions`
  | `/:account/d/:project/:branch/-/schema`
  | `/:account/d/:project/:branch/-/usage`
  | `/:account/d/:project/:branch/-/webhooks`
  | `/:account/d/:project/:branch/:entity`
  | `/:account/d/:project/:branch/:entity/*`
  | `/about`

export type Params = {
  '/-/auth/email/:id': { id: string }
  '/-/settings/*': { '*': string }
  '/:account': { account: string }
  '/:account/-/permissions': { account: string }
  '/:account/c/:project': { account: string; project: string }
  '/:account/c/:project/:branch': { account: string; project: string; branch: string }
  '/:account/d/:project': { account: string; project: string }
  '/:account/d/:project/:branch': { account: string; project: string; branch: string }
  '/:account/d/:project/:branch/-/collections': { account: string; project: string; branch: string }
  '/:account/d/:project/:branch/-/connect': { account: string; project: string; branch: string }
  '/:account/d/:project/:branch/-/permissions': { account: string; project: string; branch: string }
  '/:account/d/:project/:branch/-/schema': { account: string; project: string; branch: string }
  '/:account/d/:project/:branch/-/usage': { account: string; project: string; branch: string }
  '/:account/d/:project/:branch/-/webhooks': { account: string; project: string; branch: string }
  '/:account/d/:project/:branch/:entity': { account: string; project: string; branch: string; entity: string }
  '/:account/d/:project/:branch/:entity/*': { account: string; project: string; branch: string; entity: string; '*': string }
}

export type ModalPath = never

export const { Link, Navigate } = components<Path, Params>()
export const { useModals, useNavigate, useParams } = hooks<Path, Params, ModalPath>()
export const { redirect } = utils<Path, Params>()
