import { Button } from "@/components/ui/button";
import { Separator } from "@/components/ui/separator";
import { useBreakpoints } from "@/hooks/useBreakpoints";
import { useDataUserAccounts } from "@/hooks/useDataUserAccounts";
import { cn, projectPath } from "@/lib/utils";
import { Link, useNavigate } from "@/router";
import { Check } from "lucide-react";
import { useCallback, useState } from "react";
import { useParams } from "react-router-dom";

export function ProjectSelectorNarrow() {
    const params = useParams();
    const naviggate = useNavigate();
    const { isTouch } = useBreakpoints();
    const [accounts] = useDataUserAccounts();
    const selectedAccount = accounts?.find((account) => account.lookup === params.account);
    const [selected, setSelected] = useState<string | undefined>(selectedAccount?.id);

    const handleClick = useCallback(
        (account: { id: string; lookup: string }) => {
            if (isTouch) {
                if (selected === account.id) {
                    naviggate("/:account", { params: { account: account.lookup } });
                } else {
                    setSelected(account.id);
                }
            } else {
                naviggate("/:account", { params: { account: account.lookup } });
            }
        },
        [isTouch, naviggate, selected]
    );

    return (
        <div className="flex flex-col w-full space-x-2 p-2 md:w-[500px]">
            <div className="flex flex-col w-full md:w-[250px]">
                <Button
                    variant="ghost"
                    className={cn("justify-start rounded-sm", !params.account ? "font-bold" : "")}
                    onMouseOverCapture={() => setSelected(undefined)}
                    asChild
                >
                    <Link to="/">
                        <span className="flex flex-grow">My account</span>
                        {!params.account && <Check className="w-4 h-4 text-muted-foreground" />}
                    </Link>
                </Button>
                <Separator className="my-2" />
                {accounts?.map((account) => (
                    <>
                        <Button
                            variant="ghost"
                            className={cn(
                                "justify-start rounded-sm",
                                selected === account.id ? "bg-muted" : "",
                                account.lookup === params.account ? "font-bold" : ""
                            )}
                            key={account.id}
                            onMouseOverCapture={() => setSelected(account.id)}
                            onClickCapture={() => handleClick(account)}
                        >
                            <span className="flex flex-grow">{account.name}</span>
                            {account.lookup === params.account && <Check className="w-4 h-4 text-muted-foreground" />}
                        </Button>
                        {selected === account.id && <ProjectSelectorAccount accountId={selected} />}
                    </>
                ))}
            </div>
        </div>
    );
}

function ProjectSelectorAccount(props: { accountId: string }) {
    const [accounts] = useDataUserAccounts();
    const params = useParams();
    const account = accounts?.find((account) => account.id === props.accountId);

    return (
        <div className="flex flex-col flex-grow pl-4">
            {account?.projects?.map((project) => (
                <Button
                    variant="ghost"
                    className={cn(
                        "justify-start rounded-sm",
                        account.lookup === params.account && project.lookup === params.project ? "font-bold" : ""
                    )}
                    key={project.id}
                    asChild
                >
                    <Link to={projectPath(project.type)} params={{ account: account.lookup, project: project.lookup }}>
                        <span className="flex flex-grow">{project.name}</span>
                        {account.lookup === params.account && project.lookup === params.project && (
                            <Check className="w-4 h-4 text-muted-foreground" />
                        )}
                    </Link>
                </Button>
            ))}
        </div>
    );
}
