import { AlertFromError } from "@/components/ui/addon/alert";
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import {
    Drawer,
    DrawerContent,
    DrawerDescription,
    DrawerHeader,
    DrawerNestedRoot,
    DrawerTitle,
} from "@/components/ui/drawer";
import { ScrollArea } from "@/components/ui/scroll-area";
import { useBreakpoints } from "@/hooks/useBreakpoints";
import * as React from "react";
import { Suspense, useCallback } from "react";
import type { CombinedError } from "urql";

export function DialogOrDrawer(
    props: React.PropsWithChildren<{
        title?: string;
        description?: string;
        open?: boolean;
        error?: CombinedError | Error | null;
        disableAutoclose?: boolean;
        onOpenChange?: (open: boolean) => void;
    }>
) {
    const { desktop: isDesktop } = useBreakpoints();

    const preventAutoclose = useCallback(
        (e: { preventDefault: () => void }) => {
            if (props.disableAutoclose) {
                e.preventDefault();
            }
        },
        [props.disableAutoclose]
    );

    if (isDesktop) {
        return (
            <Dialog open={props.open} onOpenChange={props.onOpenChange}>
                <DialogContent
                    className="sm:max-w-[600px] p-2"
                    onEscapeKeyDown={preventAutoclose}
                    onPointerDownOutside={preventAutoclose}
                    onOpenAutoFocus={(e) => e.preventDefault()}
                >
                    <ScrollArea className="max-h-[80vh]">
                        <div className="p-4">
                            <DialogHeader>
                                {props.title && <DialogTitle>{props.title}</DialogTitle>}
                                {props.description && <DialogDescription>{props.description}</DialogDescription>}
                                <AlertFromError error={props.error} />
                            </DialogHeader>
                            <Suspense>{props.children}</Suspense>
                        </div>
                    </ScrollArea>
                </DialogContent>
            </Dialog>
        );
    }

    return (
        <Drawer
            open={props.open}
            onOpenChange={props.onOpenChange}
            disablePreventScroll
            dismissible={props.disableAutoclose !== true}
        >
            <DrawerContent
                onOpenAutoFocus={(e) => e.preventDefault()}
                hideDraggHandle={props.disableAutoclose === true}
            >
                <div className="max-h-[90vh] overflow-auto">
                    <DrawerHeader>
                        {props.title && <DrawerTitle>{props.title}</DrawerTitle>}
                        {props.description && <DrawerDescription>{props.description}</DrawerDescription>}
                    </DrawerHeader>
                    <AlertFromError error={props.error} />
                    <DrawerNestedRoot>
                        <div className="p-4">
                            <Suspense>{props.children}</Suspense>
                        </div>
                    </DrawerNestedRoot>
                </div>
            </DrawerContent>
        </Drawer>
    );
}
