import { graphql } from "@/generated";
import { useAuthContext } from "@/hooks/useAuthContext";
import { useMutation } from "@/hooks/useMutationDialog";
import { useCallback } from "react";

const Logout = graphql(`
    mutation Logout {
        logout
    }
`);

export function useLogoutMutation() {
    const auth = useAuthContext();
    const [, logout] = useMutation(Logout);

    const handleLogout = useCallback(async () => {
        await logout({});
        auth.logout();
    }, [auth, logout]);

    return handleLogout;
}
