/* eslint-disable @typescript-eslint/no-explicit-any */
import AvatarCell, { avatarCellTester } from "@/components/infra/jsonforms/cells/avatar";
import EnumCell, { enumCellTester } from "@/components/infra/jsonforms/cells/enum";
import TextCell, { textCellTester } from "@/components/infra/jsonforms/cells/text";
import InputControl, { inputControlTester } from "@/components/infra/jsonforms/controls/input";
import VerticalLayoutRenderer, { verticalLayoutTester } from "@/components/infra/jsonforms/layouts/vertical";
import { RankedTester } from "@jsonforms/core";

export const renderers: { tester: RankedTester; renderer: any }[] = [
    {
        tester: verticalLayoutTester,
        renderer: VerticalLayoutRenderer,
    },
    {
        tester: inputControlTester,
        renderer: InputControl,
    },
];

export const cells: { tester: RankedTester; cell: any }[] = [
    {
        tester: textCellTester,
        cell: TextCell,
    },
    {
        tester: enumCellTester,
        cell: EnumCell,
    },
    {
        tester: avatarCellTester,
        cell: AvatarCell,
    },
];
