import type { DialogComponentProps } from "@/components/DialogContextProvider";
import { UniversalActions } from "@/components/DialogOrDrawer";
import { Button } from "@/components/ui/button";
import { graphql } from "@/generated";
import { useDataAccountProjects } from "@/hooks/useDataAccountProjects";
import { useDataUserAccounts } from "@/hooks/useDataUserAccounts";
import { useMutation } from "@/hooks/useMutationDialog";
import { UPDATE_DATA, type Middleware } from "@jsonforms/core";
import { useCallback } from "react";
import { useGenUI, type GenUiSchema } from "../infra/gen-ui";
import { AlertFromError } from "../ui/addon/alert";

function generateSlug(name: string) {
    if (!name) {
        return name;
    }

    return name
        .toLowerCase()
        .replace(/[^a-z0-9-_]/g, "-")
        .replace(/[-]+/g, "-")
        .replace(/-$/g, "");
}

const schema = {
    type: "object",
    properties: {
        name: {
            type: "string",
            pattern: "^[a-zA-Z0-9-_ @#()',.]+$",
            description: "Human readable name for the environment.",
            errorMessage: "Name should not contain special characters",
        },
        lookup: {
            type: "string",
            pattern: "^[a-z0-9-_]+$",
            description: "Short stable identifier for the environment. Needs to be unique for the account.",
            errorMessage: "Use only lowercase letters, numbers, hyphens, and underscores",
        },
        type: {
            type: "string",
            enum: ["d", "c"],
            description: "Project type (d-data, c-config, e-egress). Defaults to data.",
        },
    },
    required: ["lookup", "name"],
    additionalProperties: false,
} as const satisfies GenUiSchema;

const CreateProject = graphql(`
    mutation CreateProject($lookup: String!, $name: String!, $type: Project2Type!) {
        createProject: createProject2(data: { lookup: $lookup, name: $name, type: $type }) {
            id
        }
    }
`);

export function CreateProjectDialog(props: DialogComponentProps<undefined, string>) {
    const [createProjectData, createProject] = useMutation(CreateProject);
    const [, refreshDataUserAccounts] = useDataUserAccounts();
    const [, refreshDataAccountProjects] = useDataAccountProjects();

    const middleware: Middleware = useCallback((state, action, defaultReducer) => {
        const newState = defaultReducer(state, action);

        switch (action.type) {
            case UPDATE_DATA: {
                const gen1 = generateSlug(state.data.name);
                if (newState.data.lookup === gen1) {
                    const gen2 = generateSlug(newState.data.name);
                    newState.data.lookup = gen2;
                    if (gen2?.length > 0) {
                        newState.errors = newState.errors?.filter((e) => e.keyword !== "required");
                    }
                }
                return newState;
            }
            default:
                return newState;
        }
    }, []);

    const [Form, controls] = useGenUI(schema, {
        middleware,
        onSubmit: async (d) => {
            const response = await createProject({
                ...d,
                type: d.type ?? "d",
            });
            if (response?.createProject.id) {
                refreshDataUserAccounts({ requestPolicy: "network-only" });
                refreshDataAccountProjects({ requestPolicy: "network-only" });
                props.onSubmit(response.createProject.id);
            }
        },
        defaultValue: {},
    });

    return (
        <>
            <AlertFromError error={createProjectData.error} />
            <div className="grid items-start gap-4 pt-4">
                {Form}
                <UniversalActions>
                    <Button onClick={controls.submit}>Create project</Button>
                </UniversalActions>
            </div>
        </>
    );
}

CreateProjectDialog.title = "Create project";
