import { Avatar, AvatarImage } from "@/components/ui/avatar";
import { Label } from "@/components/ui/label";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { and, EnumCellProps, isEnumControl, RankedTester, rankWith, scopeEndsWith } from "@jsonforms/core";
import { withJsonFormsEnumCellProps } from "@jsonforms/react";

const AvatarCell = (props: EnumCellProps) => {
    const { data, enabled, path, handleChange, options } = props;

    return (
        <RadioGroup
            onValueChange={(v) => handleChange(path, v)}
            defaultValue={data}
            className="grid max-w-md grid-cols-4 gap-2 pt-2"
            disabled={!enabled}
        >
            {options?.map((optionValue) => (
                <Label className="[&:has([data-state=checked])>div]:border-primary" key={optionValue.value}>
                    <RadioGroupItem value={optionValue.value} className="sr-only" />

                    <div className="items-center rounded-md border-2 border-muted p-4 hover:border-accent justify-center flex cursor-pointer">
                        <Avatar className="h-10 w-10">
                            <AvatarImage src={optionValue.label} />
                        </Avatar>
                    </div>
                </Label>
            ))}
        </RadioGroup>
    );
};

export const avatarCellTester: RankedTester = rankWith(3, and(isEnumControl, scopeEndsWith("Picture")));

export default withJsonFormsEnumCellProps(AvatarCell);
