import { PopoverLinkButton } from "@/components/PortalHeader/PopoverLinkButton";
import { ProjectSelector } from "@/components/PortalHeader/ProjectSelector";
import { TabNavigation } from "@/components/PortalHeader/TabNavigation";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Button } from "@/components/ui/button";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
} from "@/components/ui/dropdown-menu";
import { useBreakpoints } from "@/hooks/useBreakpoints";
import { useDataUserAccounts } from "@/hooks/useDataUserAccounts";
import { useLogoutMutation } from "@/hooks/useLogoutMutation";
import { useMe } from "@/hooks/useMe";
import { usePortalNavigation } from "@/hooks/usePortalNavigation";
import { projectPath } from "@/lib/utils";
import { Link } from "@/router";
import { DropdownMenuTrigger } from "@radix-ui/react-dropdown-menu";

export function Header() {
    const params = usePortalNavigation();
    const [accounts] = useDataUserAccounts();
    const { desktop: isDesktop } = useBreakpoints();

    const handleLogout = useLogoutMutation();
    const me = useMe();

    const account = accounts?.find((account) => account.lookup === params.account);
    const project = account?.projects?.find((project) => project.lookup === params.project);

    return (
        <header className="border-b bg-background px-4 md:px-6">
            <nav className="flex flex-row items-end h-12">
                <Link to="/" className="flex items-center gap-2 text-lg font-semibold md:text-base md:mr-3">
                    <img src="/v1/logo.svg" className="w-8 h-8" />
                    <span className="sr-only">Stackables Cloud</span>
                </Link>
                {isDesktop && (
                    <>
                        <div className="[&>a:last-child>span]:flex">
                            {!account && (
                                <PopoverLinkButton to="/" params={params} name={"My account"}>
                                    <ProjectSelector />
                                </PopoverLinkButton>
                            )}
                            {account && (
                                <PopoverLinkButton to="/:account" params={params} name={account.name}>
                                    <ProjectSelector />
                                </PopoverLinkButton>
                            )}
                            {project && (
                                <>
                                    <span className="text-muted-foreground">/</span>
                                    <PopoverLinkButton
                                        to={projectPath(project.type)}
                                        params={params}
                                        name={project.name}
                                    >
                                        <ProjectSelector />
                                    </PopoverLinkButton>
                                </>
                            )}
                        </div>
                        <div id="branch-portal" className="pl-16 [&>a:last-child>span]:flex" />
                    </>
                )}
                {!isDesktop && (
                    <>
                        <div className="[&>a:last-child>span]:flex flex-grow">
                            {!account && (
                                <PopoverLinkButton to="/" params={params} name={"My account"}>
                                    <ProjectSelector />
                                </PopoverLinkButton>
                            )}
                            {account && !project && (
                                <PopoverLinkButton to="/:account" params={params} name={account.name}>
                                    <ProjectSelector />
                                </PopoverLinkButton>
                            )}
                            {project && (
                                <PopoverLinkButton
                                    to={project.type === "d" ? "/:account/d/:project" : "/account/c/:project"}
                                    params={params}
                                    name={project.name}
                                >
                                    <ProjectSelector />
                                </PopoverLinkButton>
                            )}
                        </div>
                        <div id="branch-portal" className="[&>a:last-child>span]:flex" />
                    </>
                )}
                <div className="flex-grow" />
                {isDesktop && (
                    <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                            <Button variant="secondary" size="icon" className="rounded-full hover:bg-slate-300">
                                <Avatar className="h-8 w-8">
                                    {me?.profilePicture && <AvatarImage src={me?.profilePicture} />}
                                    <AvatarFallback>
                                        {me?.firstName?.substring(0, 1)}
                                        {me?.lastName?.substring(0, 1)}
                                    </AvatarFallback>
                                </Avatar>
                                <span className="sr-only">Toggle user menu</span>
                            </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent align="end" className="min-w-60">
                            <DropdownMenuLabel>My account</DropdownMenuLabel>
                            <DropdownMenuSeparator />
                            <DropdownMenuItem asChild className="cursor-pointer">
                                <Link to="/-/settings/*" params={{ "*": "" }}>
                                    Settings
                                </Link>
                            </DropdownMenuItem>
                            <DropdownMenuItem asChild className="cursor-pointer">
                                <button onClick={handleLogout} className="w-full">
                                    Logout
                                </button>
                            </DropdownMenuItem>
                        </DropdownMenuContent>
                    </DropdownMenu>
                )}
                {!isDesktop && (
                    <Button variant="secondary" size="icon" className="rounded-full hover:bg-slate-300" asChild>
                        <Link to="/-/settings/*" params={{ "*": "" }}>
                            <Avatar className="h-8 w-8">
                                {me?.profilePicture && <AvatarImage src={me?.profilePicture} />}
                                <AvatarFallback>
                                    {me?.firstName?.substring(0, 1)}
                                    {me?.lastName?.substring(0, 1)}
                                </AvatarFallback>
                            </Avatar>
                        </Link>
                    </Button>
                )}
            </nav>
            <TabNavigation />
        </header>
    );
}
