import { FullScreenLoader } from "@/components/FullScreenLoader";
import { Suspense, type PropsWithChildren } from "react";

interface FloatingFullscreenDialogProps {
    loadingText?: string;
    loading?: boolean;
    quote?: boolean;
    disableLoader?: boolean;
}

export function FloatingFullscreenDialog(props: PropsWithChildren<FloatingFullscreenDialogProps>) {
    return (
        <div className="starback h-screen">
            <div className="flex flex-col h-screen dark:backdrop-invert">
                <div className="p-6 md:p-8 z-10 md:absolute">
                    <div className="flex flex-row items-center space-x-4">
                        <img src="/v1/logo.svg" alt="Logo" className="w-12 h-12 lg:w-16 lg:h-16" />
                        <h1 className="scroll-m-20 text-3xl font-bold tracking-tight text-logo-foreground md:text-5xl">
                            Stackables Cloud
                        </h1>
                    </div>
                    {props.quote && (
                        <blockquote className="mt-6 border-l-2 pl-6 italic text-muted-foreground md:max-w-lg text-justify space-y-1">
                            <p>
                                "I always start projects with modelling the data in Stackables, then building a UI
                                prototype with GraphQL api. No time wasted on databases and back-office.
                            </p>
                            <p>
                                After we are happy with the prototype its easy to lock down the access. This workflow
                                has literally saved us years of wasted work."
                            </p>
                            <div className="text-right pt-2">
                                <p>Aarne Laur</p>
                                <p>tech enthusiast, builder</p>
                            </div>
                        </blockquote>
                    )}
                </div>
                <div className="w-full h-full content-center z-50 md:p-8 bg-opacity-70">
                    {props.loading ? <FullScreenLoader message={props.loadingText} /> : null}
                    {!props.loading && (
                        <Suspense
                            fallback={props.disableLoader ? null : <FullScreenLoader message={props.loadingText} />}
                        >
                            {props.children}
                        </Suspense>
                    )}
                </div>
            </div>
        </div>
    );
}
