import { Button } from "@/components/ui/button";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { Link } from "@/router";
import { ChevronDown } from "lucide-react";
import { useEffect, useState, type PropsWithChildren, type ReactNode } from "react";
import { useLocation } from "react-router-dom";

export function PopoverLinkButton(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    props: PropsWithChildren<{ to?: any; params?: any; name: string | ReactNode }>
) {
    const [isOpen, setIsOpen] = useState(false);
    const location = useLocation();

    useEffect(() => {
        setIsOpen(false);
    }, [location]);

    const LinkContent = () => (
        <>
            <span className="text-nowrap text-sm font-semibold">{props.name}</span>
            <span className="hidden" onClick={(e) => e.preventDefault()}>
                <PopoverTrigger asChild>
                    <Button
                        variant="ghost"
                        className="rounded-full p-0 h-6 w-6 bg-transparent hover:bg-transparent border-muted-foreground hover:border"
                        onClick={() => setIsOpen(!isOpen)}
                    >
                        <ChevronDown className="h-5 w-5" />
                    </Button>
                </PopoverTrigger>
            </span>
        </>
    );

    return (
        <Popover open={isOpen} onOpenChange={(o) => setIsOpen(o)}>
            <Button variant="ghost" size="sm" className="space-x-2 " asChild>
                {props.to ? (
                    <Link to={props.to} params={props.params}>
                        <LinkContent />
                    </Link>
                ) : (
                    <a>
                        <LinkContent />
                    </a>
                )}
            </Button>
            <PopoverContent
                className="w-[100vw] md:w-full p-0 min-h-64 flex"
                onFocusOutside={(e) => e.preventDefault()}
            >
                {props.children}
            </PopoverContent>
        </Popover>
    );
}
