/* eslint-disable @typescript-eslint/no-explicit-any */
import { cells, renderers } from "@/components/infra/jsonforms";
import type { JsonFormsCore, JsonFormsI18nState, Middleware } from "@jsonforms/core";
import { Generate } from "@jsonforms/core";
import { JsonForms } from "@jsonforms/react";
import type { ErrorObject } from "ajv";
import type { FromSchema, JSONSchema } from "json-schema-to-ts";
import { useCallback, useMemo, useState } from "react";

// type WithEmail = FromSchema<typeof schema>;
// type DeepWriteable<T> = { -readonly [P in keyof T]: DeepWriteable<T[P]> };

const i18n: JsonFormsI18nState = {
    locale: "en",
    translateError: (error: ErrorObject) => {
        if (error.keyword === "required") {
            return "This field is required";
        }
        if (error.keyword === "type" && error.data === null) {
            return "This field is required";
        }
        return error.message as any;
    },
};

export function useJsonFormsForm<T extends JSONSchema, R1 = FromSchema<T>, R2 = FromSchema<T>>(
    schema: T,
    opts: {
        onSubmit: (data: R1) => Promise<any>;
        defaultValue?: Partial<R2>;
        middleware?: Middleware;
        errors?: ErrorObject[];
    }
) {
    const [state, setState] = useState<Pick<JsonFormsCore, "data" | "errors">>({ data: opts.defaultValue ?? {} });
    const [revealErrors, setRevealErrors] = useState(false);

    const submit = useCallback(() => {
        if (!state.errors?.length && !opts.errors?.length) {
            opts.onSubmit(state.data as R1);
        } else {
            setRevealErrors(true);
        }
    }, [opts, state]);

    const config = useMemo(
        () => ({
            revealErrors,
        }),
        [revealErrors]
    );

    const Form = useMemo(() => {
        const uischema = Generate.uiSchema(schema as any);
        const initialData = state.data ?? opts.defaultValue ?? {};

        console.log("JsonForms create", initialData);

        return (
            <JsonForms
                schema={schema as any}
                data={initialData}
                uischema={uischema}
                additionalErrors={opts.errors}
                i18n={i18n}
                renderers={renderers}
                middleware={opts.middleware}
                cells={cells}
                config={config}
                onChange={setState}
                validationMode="ValidateAndShow"
            />
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [opts.errors]);

    return [
        Form,
        {
            errors: state.errors,
            submit,
        },
    ] as const;
}
