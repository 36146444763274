import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { EnumCellProps, isEnumControl, RankedTester, rankWith } from "@jsonforms/core";
import { withJsonFormsEnumCellProps } from "@jsonforms/react";

const EnumCell = (props: EnumCellProps) => {
    const { id, data, enabled, path, handleChange, options } = props;

    return (
        <Select value={data || ""} onValueChange={(v) => handleChange(path, v)} disabled={!enabled}>
            <SelectTrigger className="w-full">
                <SelectValue placeholder={data || ""} />
            </SelectTrigger>
            <SelectContent id={id}>
                <SelectGroup>
                    {options?.map((optionValue) => (
                        <SelectItem value={optionValue.value} key={optionValue.value}>
                            {optionValue.label}
                        </SelectItem>
                    ))}
                </SelectGroup>
            </SelectContent>
        </Select>
    );
};

export const enumCellTester: RankedTester = rankWith(2, isEnumControl);

export default withJsonFormsEnumCellProps(EnumCell);
