import { useMediaQuery } from "@/hooks/useMediaQuery";

export function useBreakpoints() {
    const desktop = useMediaQuery("(min-width: 768px)");
    const isTouch = useMediaQuery("(hover: none)");

    return {
        desktop,
        mobile: !desktop,
        isTouch,
    };
}
