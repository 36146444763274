/* eslint-disable @typescript-eslint/no-explicit-any */
import type { Middleware } from "@jsonforms/core";
import type { ErrorObject } from "ajv";
import type { ExtendedJSONSchema, FromExtendedSchema } from "json-schema-to-ts";
import { useJsonFormsForm } from "../jsonforms/useJsonFormsForm";

type CustomProps = {
    errorMessage?: string;
    validate?: (value: any) => Promise<string | undefined> | string | undefined;
};

type JSONSchema = ExtendedJSONSchema<CustomProps>;
type FromSchema<T extends JSONSchema> = FromExtendedSchema<CustomProps, T>;
type JSONSchemaObject = Exclude<JSONSchema, boolean>;

export type GenUiSchema = JSONSchemaObject;

function genUiToJSONSchema<T extends GenUiSchema>(schema: T): JSONSchema {
    return schema;
}

export function useGenUI<T extends GenUiSchema, R1 = FromSchema<T>, R2 = FromSchema<T>>(
    schema: T,
    opts: {
        onSubmit: (data: R1) => Promise<any>;
        defaultValue?: Partial<R2>;
        middleware?: Middleware;
        errors?: ErrorObject[];
    }
) {
    const schema2 = genUiToJSONSchema(schema);
    return useJsonFormsForm(schema2, opts);
}
