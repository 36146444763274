import { graphql } from "@/generated";
import { useQuery } from "urql";

const UseDataUserAccounts = graphql(/* GraphQL */ `
    query useDataUserAccounts {
        listUserAccounts {
            id
            name
            lookup
            projects {
                id
                name
                lookup
                type
            }
        }
    }
`);

export function useDataUserAccounts() {
    const [response, refetch] = useQuery({
        query: UseDataUserAccounts,
    });

    return [response.data?.listUserAccounts, refetch] as const;
}
