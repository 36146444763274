import { ThemeProvider } from "@/components/ui/addon/theme-provider";
import { Toaster } from "@/components/ui/sonner";
import { routes } from "@generouted/react-router/lazy";
import { StrictMode, Suspense } from "react";
import { createRoot } from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { toast } from "sonner";
import timestring from "timestring";
import { registerSW } from "virtual:pwa-register";
import "./index.css";

const intervalMS = timestring("10 seconds", "ms");

// https://vite-pwa-org.netlify.app/guide/periodic-sw-updates.html
const updateSW = registerSW({
    onRegisteredSW(swUrl, r) {
        setInterval(async () => {
            if (!r) return;

            if (r.installing || !navigator) return;

            if ("connection" in navigator && !navigator.onLine) return;

            console.log("Checking for updates...");

            const resp = await fetch(swUrl, {
                cache: "no-store",
                headers: {
                    cache: "no-store",
                    "cache-control": "no-cache",
                },
            });

            if (resp?.status === 200) await r.update();
        }, intervalMS);
    },
    onNeedRefresh() {
        toast("New Version Available", {
            id: "new-version",
            description: "Reload the page to apply new version.",
            action: {
                label: "Refresh",
                onClick: () => updateSW(),
            },
            duration: Infinity,
        });
    },
    onOfflineReady() {
        console.log("offline ready");
    },
});

export const router: ReturnType<typeof createBrowserRouter> = createBrowserRouter(routes);

createRoot(document.getElementById("root")!).render(
    <StrictMode>
        <ThemeProvider defaultTheme="system" storageKey="ui-theme">
            <Suspense>
                <RouterProvider router={router} />
                <Toaster />
            </Suspense>
        </ThemeProvider>
    </StrictMode>
);
