import { graphql } from "@/generated";
import { useQuery } from "urql";

const UseDataAccountProjects = graphql(/* GraphQL */ `
    query useDataAccountProjects {
        projects: listProject2s {
            id
            name
            lookup
            type
        }
    }
`);

export function useDataAccountProjects() {
    const [response, refetch] = useQuery({
        query: UseDataAccountProjects,
        requestPolicy: "cache-first",
    });

    return [response.data?.projects, refetch] as const;
}
