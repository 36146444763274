import { Input } from "@/components/ui/input";
import { CellProps, isStringControl, RankedTester, rankWith } from "@jsonforms/core";
import { withJsonFormsCellProps } from "@jsonforms/react";

const TextCell = (props: CellProps) => {
    const { config, data, id, enabled, schema, path, handleChange } = props;
    const maxLength = schema.maxLength;
    const appliedUiSchemaOptions = Object.apply({}, config);

    return (
        <Input
            type={appliedUiSchemaOptions.format === "password" ? "password" : "text"}
            value={data || ""}
            onChange={(ev) => handleChange(path, ev.target.value === "" ? undefined : ev.target.value)}
            id={id}
            disabled={!enabled}
            autoFocus={appliedUiSchemaOptions.focus}
            placeholder={appliedUiSchemaOptions.placeholder}
            maxLength={appliedUiSchemaOptions.restrict ? maxLength : undefined}
            size={appliedUiSchemaOptions.trim ? maxLength : undefined}
        />
    );
};

export const textCellTester: RankedTester = rankWith(1, isStringControl);

export default withJsonFormsCellProps(TextCell);
