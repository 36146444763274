import { Label } from "@/components/ui/label";
import { cn } from "@/lib/utils";
import { computeLabel, ControlProps, isControl, RankedTester, rankWith, type JsonSchema7 } from "@jsonforms/core";
import { DispatchCell, withJsonFormsControlProps } from "@jsonforms/react";
import { snakeCase, upperCaseFirst } from "change-case-all";
import { useState } from "react";

function InputControl(props: ControlProps) {
    // const ctx = useJsonForms();

    const { description, id, errors, label, uischema, schema, enabled, required, path, config } = props;
    const [touched, setTouched] = useState(false);

    // If label is auto generated the capitalize only first letter
    const capitaliser = upperCaseFirst; /* titleCase */
    const labelValue = capitaliser(
        snakeCase(path, {
            delimiter: " ",
        })
    );

    // console.log("InputControl", props);
    let hasErrors = false;
    let finalError = errors;

    if (finalError.startsWith("FORCED:")) {
        finalError = finalError.substring(7);
        hasErrors = true;
    } else if (errors.length > 0) {
        finalError = (schema as JsonSchema7).errorMessage ?? errors;
        hasErrors = (touched || config.revealErrors === true) && errors.length > 0;
    }

    return (
        <div className="grid w-full items-center gap-1.5" onBlur={() => setTouched(true)}>
            <Label htmlFor={id + "-input"} className={cn(hasErrors && "text-error-foreground")}>
                {computeLabel(labelValue ?? label, required === true, false)}
            </Label>
            <DispatchCell uischema={uischema} schema={schema} path={path} id={id + "-input"} enabled={enabled} />
            {description && <p className="text-sm text-muted-foreground">{description}</p>}
            {hasErrors && <p className="text-sm font-medium text-error-foreground">{finalError}</p>}
        </div>
    );
}

export const inputControlTester: RankedTester = rankWith(1, isControl);

export default withJsonFormsControlProps(InputControl);
