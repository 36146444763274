import { useMemo } from "react";
import { useLocation } from "react-router-dom";

export interface NavigationItem {
    name: string;
    action?: string;
    icon?: SVGSVGElement;
    since?: "md" | "lg";
}

export interface NavigationItemRuntime extends NavigationItem {
    fullLink: string;
    selected: boolean;
}

const navigation: Record<string, NavigationItem[]> = {
    user: [
        {
            name: "My teams",
        },
        {
            name: "Settings",
            action: "settings",
        },
    ],
    account: [
        {
            name: "Projects",
        },
        {
            name: "Users",
            action: "users",
        },
        {
            name: "Workers",
            action: "workers",
        },
        {
            name: "Permissions",
            action: "permissions",
        },
    ],
    d: [
        {
            name: "Data",
        },
        {
            name: "Schema",
            action: "schema",
        },
        {
            name: "API connect",
            action: "connect",
        },
        {
            name: "Webhoooks",
            action: "webhooks",
        },
        {
            name: "Collections",
            action: "collections",
        },
        {
            name: "Permissions",
            action: "permissions",
        },
        {
            name: "Usage",
            action: "usage",
        },
    ],
    c: [
        {
            name: "Applications",
        },
    ],
} as const;

export function usePortalNavigation() {
    const location = useLocation();

    return useMemo(() => {
        const [navigationPath, actionPath] = location.pathname.split("/-/");
        const [, account, type, project, branch, ...zoom] = navigationPath.split("/");
        const [action] = actionPath?.split("/") ?? [];

        let base = "";
        let links = navigation.user;

        if (account) {
            base += `/${account}`;
            links = navigation.account;
        }

        if (project) {
            base += `/${type}/${project}`;
            links = [];
        }

        if (branch) {
            base += `/${branch}`;
            links = navigation[type];
        }

        return {
            account,
            project,
            type: type as "c" | "d",
            branch,
            zoom,
            base,
            links: links.map((link) => ({
                ...link,
                fullLink: base + (link.action ? `/-/${link.action}` : ""),
                selected: link.action?.startsWith(action) || (!link.action && !action),
            })) as NavigationItemRuntime[],
            action,
        };
    }, [location]);
}
